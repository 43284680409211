export default {
    setTransactions(state, transactions) {
        state.transactions = transactions
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },
    incrementTxnCount(state, txns) {
        state.txnCount += txns
    },
    decrementTxnCount(state, txns) {
        state.txnCount -= txns
    },
    resetPageNav(state) {
        state.txnCount = 0
        state.pageEnd = false
    },
    setSelectedTxn(state, selectedTxn) {
        state.selectedTxn = selectedTxn
    },
    setPageEnd(state, pageEnd) {
        state.pageEnd = pageEnd
    },
    setStreaming(state, streaming) {
        state.streaming = streaming
    },
    setStreamIntervalId(state, streamIntervalId) {
        state.streamIntervalId = streamIntervalId
    },
    loadMoreOpsForTxn(state, payload) {
        state.selectedTxn.operations = state.selectedTxn.operations.concat(payload.operations)
        state.selectedTxn.next_operations = payload.next_operations
    }
}