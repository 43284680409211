import http from '@/utils/axios';

export default {
    fetchAssetStats({ commit }) {
        commit('setAssetStats', { loading: true })
        http.get('stats/asset')
            .then((res) => {
                commit('setAssetStats', { data: res.data.data })
            })
            .catch((error) => {
                console.log(error)
                commit('setAssetStats', { error: error })
            })
            .finally(() => {
                commit('setAssetStats', { loading: false })
            });
    },
    fetchExtendedAssetStats({ commit }) {
        commit('setExtendedAssetStats', { loading: true })
        http.get('stats/asset/extended')
            .then((res) => {
                commit('setExtendedAssetStats', { data: res.data.data })
            })
            .catch((error) => {
                console.log(error)
                commit('setExtendedAssetStats', { error: error })
            })
            .finally(() => {
                commit('setExtendedAssetStats', { loading: false })
            });
    },
    fetchBlockchainStats({ commit }) {
        commit('setBlockchainStats', { loading: true })
        http.get('stats/blockchain')
            .then((res) => {
                commit('setBlockchainStats', { data: res.data.data })
            })
            .catch((error) => {
                console.log(error)
                commit('setBlockchainStats', { error: error })
            })
            .finally(() => {
                commit('setBlockchainStats', { loading: false })
            });
    },
    fetchExtendedBlockchainStats({ commit }) {
        commit('setExtendedBlockchainStats', { loading: true })
        http.get('stats/blockchain/extended')
            .then((res) => {
                commit('setExtendedBlockchainStats', { data: res.data.data })
            })
            .catch((error) => {
                console.log(error)
                commit('setExtendedBlockchainStats', { error: error })
            })
            .finally(() => {
                commit('setExtendedBlockchainStats', { loading: false })
            });
    },
    fetchTxnValue({ commit }, payload) {

        if (Object.keys(payload.values).length) {
            commit('setTxnValue', { loading: true, error: null })
            http.post('transactions/value', payload.values)
                .then((res) => {
                    commit('setTxnValue', { data: res.data.data, append: payload.append })
                })
                .catch((error) => {
                    console.log(error)
                    commit('setTxnValue', { error: error })
                })
                .finally(() => {
                    commit('setTxnValue', { loading: false })
                });
        }
    },
    fetchLedgerValue({ commit }, values) {
        if (Object.keys(values).length) {
            commit('setLedgerValue', { loading: true, error: null })
            http.post('ledgers/value', values)
                .then((res) => {
                    commit('setLedgerValue', { data: res.data.data })
                })
                .catch((error) => {
                    console.log(error)
                    commit('setLedgerValue', { error: error })
                })
                .finally(() => {
                    commit('setLedgerValue', { loading: false })
                });
        }
    }
}