export default {
    getLatestClaimableBalances(state) {
        return "records" in state.claimableBalances ? state.claimableBalances.records : []
    },
    getLoadingStatus(state) {
        return state.loading
    },
    getError(state) {
        return state.error
    },
    disablePrevBtn(state, getters) {

        return state.error || state.loading || !getters.getLatestClaimableBalances.length || state.claimableBalancesCount <= process.env.VUE_APP_API_LIMIT
    },
    disableNextBtn(state, getters) {

        return state.error || state.loading || !getters.getLatestClaimableBalances.length || state.pageEnd
    },
}
