export default () => ({
    operations: {},
    loading: false,
    error: null,

    // Used for page switching
    opsCount: 0,
    pageEnd: false,

    // State for operation details
    selectedOp: {},

    // State for streaming operations
    streaming: false,
    streamIntervalId: null
})
