export default {
    setOperations(state, operations) {
        state.operations = operations
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },
    incrementOpsCount(state, ops) {
        state.opsCount += ops
    },
    decrementOpsCount(state, ops) {
        state.opsCount -= ops
    },
    resetPageNav(state) {
        state.opsCount = 0
        state.pageEnd = false
    },
    setSelectedOp(state, selectedOp) {
        state.selectedOp = selectedOp
    },
    setPageEnd(state, pageEnd) {
        state.pageEnd = pageEnd
    },
    setStreaming(state, streaming) {
        state.streaming = streaming
    },
    setStreamIntervalId(state, streamIntervalId) {
        state.streamIntervalId = streamIntervalId
    },
}