export default () => ({
    trades: {},
    loading: false,
    error: null,

    // Used for page switching
    tradesCount: 0,
    pageEnd: false,

    // State for streaming trades
    streaming: false,
    streamIntervalId: null
})
