export default () => ({
    contracts: {},
    loading: true,
    error: null,

    // Used for page switching
    contractsCount: 0,

    // State for ledger details
    selectedContract: {},
    pageEnd: false,
    pageStart: false,

    // State for streaming transactions
    streaming: false,
    streamIntervalId: null,
    loadingContractVerify: false,
    statusContractVerify: 0,
})
