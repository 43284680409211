export default {
    setAssets(state, assets) {
        state.assets = Object.assign(state.assets, assets)
    },
    setAssetsMeta(state, assetsMeta) {
        state.assetsMeta = Object.assign(state.assetsMeta, assetsMeta)
    },
    setSelectedAsset(state, selectedAsset) {
        state.selectedAsset = Object.assign(state.selectedAsset, selectedAsset)
    },
    setAssetMeta(state, meta) {
        if (state.selectedAsset) { state.selectedAsset.data.meta = meta }
    },
    setAssetTradesAggregated(state, aggregatedTrades) {
        state.aggregatedTrades = aggregatedTrades
    },
    setMoreAssetTradesAggregated(state, aggregatedTrades) {
        state.aggregatedTrades.records = state.aggregatedTrades.records.concat(aggregatedTrades.records)
        state.aggregatedTrades.next = aggregatedTrades.next
        state.aggregatedTrades.prev = aggregatedTrades.prev
    },
    setAssetChartsData(state, assetChartsData) {
        state.assetChartsData = Object.assign(state.assetChartsData, assetChartsData)
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setLoadingCandles(state, loading) {
        state.loadingCandles = loading
    },
    setOrderBook(state, orderBook) {
        state.orderBook = orderBook
    },
    setTrades(state, latestTrades) {
        state.trades = latestTrades
    },
    setStreamingTrades(state, isStreaming) {
        state.isStreamingTrades = isStreaming
    },
    setStreamingOrderBook(state, isStreaming) {
        state.isStreamingOrderBook = isStreaming
    },
    addStreamTrade(state, trade) {
        state.trades.records = [trade, ...state.trades.records]
    },
    setLatestXlmPrice(state, price){
        state.latestPrice = price;
    }
}
