export default {
    getAssets(state) {
        return state.assets
    },
    getAssetsMeta(state) {
        return state.assetsMeta
    },
    getSelectedAsset(state) {
        return state.selectedAsset
    },
    getAssetChartsData(state) {
        return state.assetChartsData
    },
    getLoadingStatus(state) {
        return state.loading
    },
    getLoadingCandlesStatus(state) {
        return state.loadingCandles
    },
    getOrderBook(state) {
        return state.orderBook
    },
    getAssetTrades(state) {
        return state.trades
    },
    getAssetTradesAggregated(state) {
        return state.aggregatedTrades.records
    },
    getNextAssetTradesAggregated(state) {
        return state.aggregatedTrades.next
    },
    getLatestXlmPrice(state){
        return state.latestPrice;
    }
}
