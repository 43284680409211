export default {
    getLatestOffers(state) {
        return "records" in state.offers ? state.offers.records : []
    },
    getLoadingStatus(state) {
        return state.loading
    },
    getError(state) {
        return state.error
    },
    disablePrevBtn(state, getters) {

        return state.error || state.loading || state.streaming || !getters.getLatestOffers.length || state.offersCount <= process.env.VUE_APP_API_LIMIT
    },
    disableNextBtn(state, getters) {

        return state.error || state.loading || state.streaming || !getters.getLatestOffers.length || state.pageEnd
    },
    getStreamIntervalId(state) {
        return state.streamIntervalId
    },
    isStreaming(state) {
        return state.streaming
    },
}
