export default {
    getLatestEffects(state) {
        return "records" in state.effects ? state.effects.records : []
    },
    getLoadingStatus(state) {
        return state.loading
    },
    getError(state) {
        return state.error
    },
    disablePrevBtn(state, getters) {

        return state.error || state.loading || state.streaming || !getters.getLatestEffects.length || state.effectsCount <= process.env.VUE_APP_API_LIMIT
    },
    disableNextBtn(state, getters) {

        return state.error || state.loading || state.streaming || !getters.getLatestEffects.length || state.pageEnd
    },
    getSelectedEff(state) {
        return state.selectedEff
    },
    getStreamIntervalId(state) {
        return state.streamIntervalId
    },
    isStreaming(state) {
        return state.streaming
    }
}