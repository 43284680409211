export default {
    getLatestOperations(state) {
        return "records" in state.operations ? state.operations.records : []
    },
    getLoadingStatus(state) {
        return state.loading
    },
    getError(state) {
        return state.error
    },
    getSelectedOp(state) {
        return state.selectedOp
    },
    disablePrevBtn(state, getters) {

        return state.error || state.loading || state.streaming || !getters.getLatestOperations.length || state.opsCount <= process.env.VUE_APP_API_LIMIT
    },
    disableNextBtn(state, getters) {

        return state.error || state.loading || state.streaming || !getters.getLatestOperations.length || state.pageEnd
    },
    disableDetailsPrevBtn(state, getters, rootState, rootGetters) {
        const txn = rootGetters['transactions/getSelectedTxn']
        return state.error || state.loading || !getters.getLatestOperations.length || 'operation_count' in txn && state.opsCount <= process.env.VUE_APP_API_LIMIT
    },
    disableDetailsNextBtn(state, getters, rootState, rootGetters) {

        const txn = rootGetters['transactions/getSelectedTxn']
        return state.error || state.loading || !getters.getLatestOperations.length || txn && 'operation_count' in txn && state.opsCount >= txn.operation_count
    },
    getStreamIntervalId(state) {
        return state.streamIntervalId
    },
    isStreaming(state) {
        return state.streaming
    },
}
