
export const getDefaultState = () => ({
    effects: {},
    loading: false,
    error: null,

    // Used for page navigation
    effectsCount: 0,
    pageEnd: false,

    // State for effect details
    selectedEff: {},

    // State for streaming effects
    streaming: false,
    stopStreamFunc: null
})