
export default {
    // Fetching payments and switching pages
    fetchPayments({ commit, rootGetters }) {
        commit('resetPageNav')
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .payments()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .call()
            .then((response) => {
                commit('setPayments', response)
                commit('incrementPaymentsCount', response.records.length)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchNextPage({ state, commit, rootGetters }) {
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .payments()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .cursor(state.payments.records[state.payments.records.length - 1].paging_token)
            .call()
            .then((response) => {
                if (response.records.length) {
                    commit('setPayments', response)
                    commit('incrementPaymentsCount', response.records.length)
                } else {
                    commit('setPageEnd', true)
                }
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });

    },
    fetchPrevPage({ commit, state, rootGetters }) {
        commit('setLoading', true)
        commit('decrementPaymentsCount', state.payments.records.length)
        rootGetters['settings/getApiServer']
            .payments()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("asc")
            .cursor(state.payments.records[0].paging_token)
            .call()
            .then((response) => {
                response.records = response.records.reverse()
                commit('setPayments', response)
                commit('setPageEnd', false)
            })
            .catch((error) => {
                console.log(error)
                commit('incrementPaymentsCount', state.payments.records.length)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },

}
