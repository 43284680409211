import http from '@/utils/axios';

export default {
    fetchFinancialData({ commit }, query) {
        commit('setLoading', true)
        http.get('stats/asset/graphs', {
            params: {
                agg: query.aggregation,
                currency: query.currency,
                graph: query.graph
            }
        })
            .then((res) => {
                commit('setFinancialData', {graph: query.graph, data: res.data.data })
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchBlockchainData({ commit }, query) {
        commit('setLoading', true)
        http.get('stats/blockchain/graphs', {
            params: {
                agg: query.aggregation,
                graph: query.graph
            }
        })
            .then((res) => {
                commit('setBlockchainData', {graph: query.graph, data: res.data.data })
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    convertPrices({ commit }) {
        commit('setConversionPrices', { loading: true })
        return http.get('assets/prices',
        )
            .then((res) => {
                commit('setConversionPrices', { data: res.data.data })
            })
            .catch((error) => {
                console.log(error)
                commit('setConversionPrices', { error })
            }).finally(() => {
                commit('setConversionPrices', { loading: false })
            })
    },
    searchPrice(_, searchDate) {
        return http.get('stats/asset/price-search', {
            params: {
                'date': searchDate
            }
        })
            .then((res) => res.data.data)
            .catch((error) => {
                console.log(error)
            })
    }
}
