import { getDefaultState } from "./state";

export default {
    setEffects(state, effects) {
        state.effects = effects
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },
    incrementEffectsCount(state, effects) {
        state.effectsCount += effects
    },
    decrementEffectsCount(state, effects) {
        state.effectsCount -= effects
    },
    resetPageNav(state) {
        state.effectsCount = 0
        state.pageEnd = false
    },
    setPageEnd(state, pageEnd) {
        state.pageEnd = pageEnd
    },
    setSelectedEff(state, selectedEff) {
        state.selectedEff = selectedEff
    },
    setStreaming(state, streaming) {
        state.streaming = streaming
    },
    setStreamIntervalId(state, streamIntervalId) {
        state.streamIntervalId = streamIntervalId
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}
