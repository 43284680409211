export default {
    setFinancialData(state, financialData) {
        let updatedFinancialData = {
            ...state.financialData,
        };
        updatedFinancialData[financialData.graph] = financialData.data;
        state.financialData = updatedFinancialData;
    },
    setBlockchainData(state, blockchainData) {
        let updatedBlockchainData = {
            ...state.blockchainData,
        };
        updatedBlockchainData[blockchainData.graph] = blockchainData.data;
        state.blockchainData= updatedBlockchainData;
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },
    setConversionPrices(state, conversionPrices) {
        state.conversionPrices = Object.assign(state.conversionPrices, conversionPrices)
    },
}
