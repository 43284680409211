export default {
    setLiquidityPools(state, liquidityPools) {
        state.liquidityPools = liquidityPools
    },
    setLiquidityPool(state, liquidityPool) {
        state.liquidityPool = liquidityPool
    },
    setLiquidityPoolTrades(state, liquidityPool) {
        state.liquidityPoolTrades = liquidityPool
    },
    setMoreLiquidityPoolTrades(state, liquidityPool) {
        state.liquidityPoolTrades.records = state.liquidityPoolTrades.records.concat(liquidityPool.records)
        state.liquidityPoolTrades.next = liquidityPool.next
    },
    setLiquidityPoolOperations(state, liquidityPool) {
        state.liquidityPoolOperations = liquidityPool
    },
    setMoreLiquidityPoolOperations(state, liquidityPool) {
        state.liquidityPoolOperations.records = state.liquidityPoolOperations.records.concat(liquidityPool.records)
        state.liquidityPoolOperations.next = liquidityPool.next
    },
    setLiquidityPoolTransactions(state, liquidityPool) {
        state.liquidityPoolTransactions = liquidityPool
    },
    setMoreLiquidityPoolTransactions(state, liquidityPool) {
        state.liquidityPoolTransactions.records = state.liquidityPoolTransactions.records.concat(liquidityPool.records)
        state.liquidityPoolTransactions.next = liquidityPool.next
    },
    setLiquidityPoolEffects(state, liquidityPool) {
        state.liquidityPoolEffects = liquidityPool
    },
    setMoreLiquidityPoolEffects(state, liquidityPool) {
        state.liquidityPoolEffects.records = state.liquidityPoolEffects.records.concat(liquidityPool.records)
        state.liquidityPoolEffects.next = liquidityPool.next
    },
    getLoadingStatus(state) {
        return state.loading
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setLoadingTab(state, loading) {
        state.loadingTab = loading
    },
    setError(state, error) {
        state.error = error
    },
    incrementLpsCount(state, liquidityPools) {
        state.liquidityPoolsCount += liquidityPools
    },
    decrementLpsCount(state, liquidityPools) {
        state.liquidityPoolsCount -= liquidityPools
    },
    resetPageNav(state) {
        state.liquidityPoolsCount = 0
        state.pageEnd = false
    },
    setPageEnd(state, pageEnd) {
        state.pageEnd = pageEnd
    }
}
