export default {
    setPayments(state, payments) {
        state.payments = payments
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },
    incrementPaymentsCount(state, paymentsCount) {
        state.paymentsCount += paymentsCount
    },
    decrementPaymentsCount(state, paymentsCount) {
        state.paymentsCount -= paymentsCount
    },
    resetPageNav(state) {
        state.paymentsCount = 0
        state.pageEnd = false
    },
    setPageEnd(state, pageEnd) {
        state.pageEnd = pageEnd
    }
}