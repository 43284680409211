import axios from "axios"

const http = axios.create({
    timeout: 0,
    baseURL: process.env.VUE_APP_API_URL
})
const debug = process.env.NODE_ENV === "development";
http.interceptors.request.use((config) => {
    return config;
}, (error) => {
    if (debug) {
        console.log(error);
    }
    return Promise.reject(error);
});

http.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (debug) {
        console.log(error);
    }
    return Promise.reject(error);
});

export default http;
