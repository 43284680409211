export default () => ({
    transactions: {},
    loading: true,
    error: null,

    // Used for page switching
    txnCount: 0,

    // State for ledger details
    selectedTxn: {},
    pageEnd: false,

    // State for streaming transactions
    streaming: false,
    streamIntervalId: null

})
