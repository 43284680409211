export default {
    // Fetching claimable balances and switching pages
    fetchClaimableBalances({ commit, rootGetters }) {
        commit('resetPageNav')
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .claimableBalances()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .call()
            .then((response) => {
                commit('setClaimableBalances', response)
                commit('incrementCbsCount', response.records.length)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchNextPage({ state, commit, rootGetters }) {
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .claimableBalances()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .cursor(state.claimableBalances.records[state.claimableBalances.records.length - 1].paging_token)
            .call()
            .then((response) => {
                if (response.records.length) {
                    commit('setClaimableBalances', response)
                    commit('incrementCbsCount', response.records.length)
                } else {
                    commit('setPageEnd', true)
                }
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });

    },
    fetchPrevPage({ commit, state, rootGetters }) {
        commit('setLoading', true)
        commit('decrementCbsCount', state.claimableBalances.records.length)
        rootGetters['settings/getApiServer']
            .claimableBalances()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("asc")
            .cursor(state.claimableBalances.records[0].paging_token)
            .call()
            .then((response) => {
                response.records = response.records.reverse()
                commit('setClaimableBalances', response)
                commit('setPageEnd', false)
            })
            .catch((error) => {
                console.log(error)
                commit('incrementCbsCount', state.claimableBalances.records.length)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
}
