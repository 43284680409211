import http from '@/utils/axios'
import {NetworksEnum} from "@/store/modules/settings/state";
import {decodeTxnSorobanXdr} from '../../../utils/stellar-sdk';
import {loadScript} from "vue-plugin-load-script";

import * as StellarSdk from 'stellar-sdk';
const {xdr, StrKey} = StellarSdk;

// tslint:disable-line
let wabt = null;
loadScript("https://webassembly.github.io/wabt/demo/libwabt.js")
    .then(() => {
        wabt = WabtModule();
    })
    .catch(() => {
    });

export default {
    // Fetching transactions and switching pages
    fetchContracts({commit, rootGetters, dispatch}, limit) {
        commit('resetPageNav')
        commit('setLoading', true)

        let networks = {
            0: 'public',
            1: 'test'
        }
        let network = networks[rootGetters['settings/getNetworkType']];
        return http.get(`contracts/env/` + network)
            .then(async (response) => {
                if (response.data.prev_page_url === null) {
                    commit('setPageStart', true)
                }
                commit('setContracts', response.data)
                commit('incrementContractsCount', response.data.length)
                return response
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchNextPage({state, commit, rootGetters, dispatch}) {
        commit('setLoading', true)
        if (state.contracts.next_page_url === null) {
            commit('setPageEnd', true)
            return false;
        }
        return http.get(state.contracts.next_page_url)
            .then(async (response) => {
                if (response.data.data.length) {
                    const accounts = await dispatch("accounts/formatAccountsFromTransactions", response.data.data, {root: true})
                    await dispatch("accounts/fetchAccountsLabels", {accounts}, {root: true})
                    const values = await dispatch('formatTxnsValues', response.data.data)
                    await dispatch("analytics/fetchTxnValue", {values}, {root: true});
                    commit('setContracts', response.data.data)
                    commit('incrementContractsCount', response.data.data.length)
                    commit('setPageStart', false)
                } else {
                    commit('setPageEnd', true)
                }
                commit('setContracts', response.data)
                commit('incrementContractsCount', response.data.length)
                return response
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchPrevPage({commit, state, rootGetters, dispatch}) {
        commit('setLoading', true)
        commit('decrementTxnCount', state.contracts.data.length)
        if (state.contracts.prev_page_url === null) {
            return false;
        }
        return http.get(state.contracts.prev_page_url)
            .then(async (response) => {
                const accounts = await dispatch("accounts/formatAccountsFromTransactions", response.data.data, {root: true})
                await dispatch("accounts/fetchAccountsLabels", {accounts}, {root: true})
                const values = await dispatch('formatTxnsValues', response.data.data)
                await dispatch("analytics/fetchTxnValue", {values}, {root: true});
                commit('setContracts', response.data)
                commit('setPageEnd', false)
            })
            .catch((error) => {
                console.log(error)
                commit('incrementContractsCount', state.contract.data.length)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    async checkTransactionContract(_, response) {
        if (response.contractDecoded?.code_xdr){
            let buffer = Buffer.from(response.contractDecoded.code_xdr, 'base64');
            let contract = xdr.LedgerEntryData.fromXDR(buffer, 'base64')
            let contractId = contract.value().hash().toString('hex');
            let contractWasm = contract.value().code();
            if (contractId) {
                //server xdr byte code
                const buffer = Buffer.from(contractWasm);
                const wat = await wabt.then(function (wabt) {
                    var module = wabt.readWasm(buffer, {readDebugNames: false});
                    return module.toText({foldExprs: true, inlineExport: true});
                })
                response.wat = wat;
                response.wasm = contractWasm;
                return response;
            }
        }
    },
    getContractsForTxns(_, {dispatch, response}) {
        response.records.forEach(async (txn) => {
            await dispatch("checkTransactionContract", txn);
        });
    },
    // Extract ops from txn XDR
    decodeTxnsOperations(_, txns) {
        return txns.records.map(async (txn) => {
            try {
                txn.operations = decodeTxnSorobanXdr(txn.envelope_xdr).operations()
            }
            catch (error) {
                console.log(error)
                txn.operations = []
            }
        });
    },
    formatSelectedTxnValues(_, operations) {
        let values = []
        operations.forEach(operation => {
            switch (operation.type) {
                case 'create_account':
                    values.push({
                        amount: operation.starting_balance,
                        asset_type: 'native',
                        asset_code: null,
                        asset_issuer: null
                    })
                    break;
                case 'payment':
                case 'path_payment_strict_receive':
                case 'clawback':
                    values.push({
                        amount: operation.amount,
                        asset_type: operation.asset_type,
                        asset_code: operation.asset_code,
                        asset_issuer: operation.asset_issuer
                    })
                    break;
                case 'path_payment_strict_send':
                    values.push({
                        amount: operation.source_amount,
                        asset_type: operation.source_asset_type,
                        asset_code: operation.source_asset_code,
                        asset_issuer: operation.source_asset_issuer
                    })
                    break;
                case 'manage_sell_offer':
                case 'create_passive_sell_offer':
                    values.push({
                        amount: operation.amount,
                        asset_type: operation.selling_asset_type,
                        asset_code: operation.selling_asset_code,
                        asset_issuer: operation.selling_asset_issuer
                    })
                    break;
                case 'manage_buy_offer':
                    values.push({
                        amount: operation.amount,
                        asset_type: operation.buying_asset_type,
                        asset_code: operation.buying_asset_code,
                        asset_issuer: operation.buying_asset_issuer
                    })
                    break;
                case 'create_claimable_balance':
                    {
                        let asset = {}
                        if (operation.asset === 'native') {
                            asset.type = 'native'
                        } else {
                            const arr = operation.asset.split(':')
                            arr[0].length > 4 ? asset.type = 'credit_alphanum12' : asset.type = 'credit_alphanum4'
                            asset.code = arr[0]
                            asset.issuer = arr[1]
                        }
                        values.push({
                            amount: operation.amount,
                            asset_type: asset.type,
                            asset_code: asset.code,
                            asset_issuer: asset.issuer
                        })
                        break;
                    }
                default:
                    break;
            }

        });
        return values
    },
    // Fetching txn by txn hash
    fetchContractById({commit, rootGetters, dispatch}, contractId) {
        commit('setLoading', true)
        let env = 'public';
        if (rootGetters['settings/getNetworkType'] === NetworksEnum.testNet) {
            env = 'testnet';
        }
        return http.get(`contracts/${env}/${contractId}/show`)
            .then(async (response) => {
                commit('setSelectedContract', response.data)
                console.log(response.data)
                await dispatch("checkTransactionContract", response.data);
                return response
            })
            .catch((error) => {
                commit('setError', error)
                return false;
            })
            .finally(() => {
                commit('setLoading', false)
            });

    },
    uploadContractSourceZip({commit}, payload) {
        commit('setLoadingContractVerify', true)
        const formData = new FormData();
        formData.append('file', payload.sourceZip);
        return http.post(`contracts/${payload.contractId}/verify`, formData, {'Content-Type': 'multipart/form-data'})
            .then(async (response) => {
                commit('setStatusContractVerify', response.data)
            })
            .catch((error) => {
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoadingContractVerify', false)
            });
    },
    checkStatusContractSource({commit}, payload) {
        return http.get(`contracts/${payload.contractId}`)
            .then(async (response) => {
                commit('setStatusContractVerify', response.data.source_code_verified)
            })
            .catch((error) => {
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoadingContractVerify', false)
            });
    },
    async fetchMoreOpsForTxn({getters, commit, dispatch}) {
        if (getters.getSelectedTxn.next_operations) {
            commit('operations/setLoading', true, {root: true})
            commit('operations/setError', null, {root: true})
            try {
                const operations = await getters.getSelectedTxn.next_operations()
                commit('loadMoreOpsForTxn', {operations: operations.records, next_operations: operations.records.length ? operations.next : null})
                const values = await dispatch("formatSelectedTxnValues", getters.getSelectedTxn.operations)
                const txnAccs = await dispatch("accounts/formatAccountsFromTransactions", [getters.getSelectedTxn], {root: true})
                const opAccs = await dispatch("accounts/formatAccountsFromOperations", getters.getSelectedTxn.operations, {root: true})
                await dispatch("accounts/fetchAccountsLabels", {accounts: txnAccs.concat(opAccs)}, {root: true})
                await dispatch("analytics/fetchTxnValue", {values: {[getters.getSelectedTxn.hash]: {operations: values, fee: getters.getSelectedTxn.fee_charged}}}, {root: true});
            } catch (error) {
                commit('operations/setError', error, {root: true})
            } finally {
                commit('operations/setLoading', false, {root: true})
            }
        }
    },
    async startStreaming({rootGetters, commit, dispatch}) {
        await dispatch("fetchContracts", process.env.VUE_APP_API_LIMIT);
        commit('setStreaming', true)
    },
    stopStreaming({commit, getters}) {
        clearInterval(getters.getStreamIntervalId)
        commit('setStreaming', false)
        commit('setStreamIntervalId', null)
    },
    formatTxnsValues(_, transactions) {
        let values = {}
        transactions.forEach(transaction => {
            values[transaction.hash] = {}
            values[transaction.hash]['operations'] = []
            if ('operations' in transaction) {
                transaction.operations.forEach(operation => {
                    switch (operation.body().switch().name) {
                        case 'createAccount': {
                            values[transaction.hash]['operations'].push({
                                amount: operation.body().value().startingBalance().toString() * 0.0000001,
                                asset_type: 'native',
                                asset_code: null,
                                asset_issuer: null
                            })
                            break;
                        }
                        case 'pathPaymentStrictReceive':
                            {
                                let assetCode = null;
                                let assetIssuer = null;
                                if (operation.body().value().destAsset().switch().name !== 'assetTypeNative') {
                                    assetCode = Array.from(operation.body().value().destAsset().value().assetCode());
                                    while (!assetCode[assetCode.length - 1]) {
                                        assetCode.pop()
                                    }
                                    assetCode = new TextDecoder('utf-8').decode(new Uint8Array(assetCode))
                                    assetIssuer = StrKey.encodeEd25519PublicKey(operation.body().value().destAsset().value().issuer().ed25519())
                                }

                                values[transaction.hash]['operations'].push({
                                    amount: operation.body().value().destAmount().toString() * 0.0000001,
                                    asset_type: operation.body().value().destAsset().switch().name,
                                    asset_code: assetCode,
                                    asset_issuer: assetIssuer
                                })
                                break;
                            }
                        case 'manageSellOffer':
                        case 'createPassiveSellOffer':
                            {
                                let assetCode = null;
                                let assetIssuer = null;
                                if (operation.body().value().selling().switch().name !== 'assetTypeNative') {
                                    assetCode = Array.from(operation.body().manageSellOfferOp().selling().value().assetCode());
                                    while (!assetCode[assetCode.length - 1]) {
                                        assetCode.pop()
                                    }
                                    assetCode = new TextDecoder('utf-8').decode(new Uint8Array(assetCode))
                                    assetIssuer = StrKey.encodeEd25519PublicKey(operation.body().manageSellOfferOp().selling().value().issuer().ed25519())
                                }

                                values[transaction.hash]['operations'].push({
                                    amount: operation.body().value().amount().toString() * 0.0000001,
                                    asset_type: operation.body().value().selling().switch().name,
                                    asset_code: assetCode,
                                    asset_issuer: assetIssuer
                                })
                                break;
                            }

                        case 'manageBuyOffer':
                            {
                                let assetCode = null;
                                let assetIssuer = null;

                                if (operation.body().value().buying().switch().name !== 'assetTypeNative') {
                                    assetCode = Array.from(operation.body().manageBuyOfferOp().buying().value().assetCode());
                                    while (!assetCode[assetCode.length - 1]) {
                                        assetCode.pop()
                                    }
                                    assetCode = new TextDecoder('utf-8').decode(new Uint8Array(assetCode))
                                    assetIssuer = StrKey.encodeEd25519PublicKey(operation.body().manageBuyOfferOp().buying().value().issuer().ed25519())
                                }

                                values[transaction.hash]['operations'].push({
                                    amount: operation.body().value().buyAmount().toString() * 0.0000001,
                                    asset_type: operation.body().value().buying().switch().name,
                                    asset_code: assetCode,
                                    asset_issuer: assetIssuer
                                })
                                break;
                            }
                        case 'pathPaymentStrictSend': {
                            let assetCode = null;
                            let assetIssuer = null;

                            if (operation.body().value().sendAsset().switch().name !== 'assetTypeNative') {
                                assetCode = Array.from(operation.body().value().sendAsset().value().assetCode());
                                while (!assetCode[assetCode.length - 1]) {
                                    assetCode.pop()
                                }
                                assetCode = new TextDecoder('utf-8').decode(new Uint8Array(assetCode))
                                assetIssuer = StrKey.encodeEd25519PublicKey(operation.body().value().sendAsset().value().issuer().ed25519())
                            }

                            values[transaction.hash]['operations'].push({
                                amount: operation.body().value().sendAmount().toString() * 0.0000001,
                                asset_type: operation.body().value().sendAsset().switch().name,
                                asset_code: assetCode,
                                asset_issuer: assetIssuer
                            })
                            break;
                        }
                        case 'payment':
                        case 'createClaimableBalance':
                        case 'clawback': {
                            let assetCode = null;
                            let assetIssuer = null;

                            if (operation.body().value().asset().switch().name !== 'assetTypeNative') {
                                assetCode = Array.from(operation.body().value().asset().value().assetCode());
                                while (!assetCode[assetCode.length - 1]) {
                                    assetCode.pop()
                                }
                                assetCode = new TextDecoder('utf-8').decode(new Uint8Array(assetCode))
                                assetIssuer = StrKey.encodeEd25519PublicKey(operation.body().value().asset().value().issuer().ed25519())
                            }

                            values[transaction.hash]['operations'].push({
                                amount: operation.body().value().amount().toString() * 0.0000001,
                                asset_type: operation.body().value().asset().switch().name,
                                asset_code: assetCode,
                                asset_issuer: assetIssuer
                            })
                            break;
                        }
                        default:
                            break;
                    }
                });
            }
            values[transaction.hash]['fee'] = transaction.fee_charged * 0.0000001
        });
        return values;
    }
}
