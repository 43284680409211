export default {
    // Fetching operations and switching pages
    fetchOperations({ commit, rootGetters, dispatch }) {
        commit('resetPageNav')
        commit('setLoading', true)
        rootGetters['settings/getApiServer'].operations()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .call()
            .then(async (response) => {
                commit('setOperations', response)
                const accounts = await dispatch("accounts/formatAccountsFromOperations", response.records, { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts }, { root: true })
                commit('incrementOpsCount', response.records.length)
                dispatch('fetchOperationsAssetsMeta', response.records)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchNextPage({ state, commit, rootGetters, dispatch }) {
        commit('setLoading', true)
        rootGetters['settings/getApiServer'].operations()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .cursor(state.operations.records[state.operations.records.length - 1].paging_token)
            .call()
            .then(async (response) => {
                if (response.records.length) {
                    commit('setOperations', response)
                    const accounts = await dispatch("accounts/formatAccountsFromOperations", response.records, { root: true })
                    await dispatch("accounts/fetchAccountsLabels", { accounts }, { root: true })
                    commit('incrementOpsCount', response.records.length)
                    dispatch('fetchOperationsAssetsMeta', response.records)
                } else {
                    commit('setPageEnd', true)
                }
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });

    },
    fetchPrevPage({ commit, state, rootGetters, dispatch }) {
        commit('setLoading', true)
        commit('decrementOpsCount', state.operations.records.length)
        rootGetters['settings/getApiServer'].operations()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("asc")
            .cursor(state.operations.records[0].paging_token)
            .call()
            .then(async (response) => {
                response.records = response.records.reverse()
                commit('setOperations', response)
                const accounts = await dispatch("accounts/formatAccountsFromOperations", response.records, { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts }, { root: true })
                commit('setPageEnd', false)
                dispatch('fetchOperationsAssetsMeta', response.records)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
                commit('incrementOpsCount', state.operations.records.length)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    // Fetching operation by op id
    fetchOpById({ commit, dispatch, rootGetters }, opId) {
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .operations()
            .operation(opId)
            .call()
            .then(async (response) => {
                const effects = await response.effects()
                const opAccs = await dispatch("accounts/formatAccountsFromOperations", [response], { root: true })
                const effAccs = await dispatch("accounts/formatAccountsFromEffects", effects.records, { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts: opAccs.concat(effAccs) }, { root: true })
                response.effects = effects.records
                response.next_effects = effects.next
                commit('setSelectedOp', response)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    // Start/stop streaming operations
    async startStreaming({ rootGetters, commit, dispatch }) {
        await dispatch("fetchOperations", process.env.VUE_APP_API_LIMIT);
        commit('setStreaming', true)
        const stop = setInterval(() => {
            rootGetters['settings/getApiServer']
                .operations()
                .limit(process.env.VUE_APP_API_LIMIT)
                .order("desc")
                .call()
                .then(async (response) => {
                    commit('setOperations', response);
                    const accounts = await dispatch("accounts/formatAccountsFromOperations", response.records, { root: true })
                    await dispatch("accounts/fetchAccountsLabels", { accounts }, { root: true })
                    dispatch('fetchOperationsAssetsMeta', response.records)
                })
                .catch((error) => {
                    console.log(error)
                    commit('setError', error)
                    commit('setStreaming', false)
                    commit('setStreamIntervalId', null)
                })
        }, 5000)
        commit('setStreamIntervalId', stop)
    },
    stopStreaming({ commit, getters }) {
        clearInterval(getters.getStreamIntervalId)
        commit('setStreaming', false)
        commit('setStreamIntervalId', null)
    },
    // Fetch assets meta for operations that have assets
    fetchOperationsAssetsMeta({ dispatch }, operations) {
        let assets = new Set()
        operations.forEach((op) => {
            if ('asset' in op) {
                op.asset !== 'native' ? assets.add({ asset_type: op.asset.split(":")[0].length <= 4 ? 'credit_alphanum4' : 'credit_alphanum12', asset_code: op.asset.split(":")[0], asset_issuer: op.asset.split(":")[1] }) :
                    assets.add({ asset_type: op.asset, asset_code: null, asset_issuer: null });
            }
            if ('asset_type' in op &&
                'asset_code' in op &&
                'asset_issuer' in op &&
                'liquidity_pool_shares' !== op.asset_type) { assets.add({ asset_type: op.asset_type, asset_code: op.asset_code, asset_issuer: op.asset_issuer }) }
            if ('selling_asset_type' in op) { assets.add({ asset_type: op.selling_asset_type, asset_code: op.selling_asset_code, asset_issuer: op.selling_asset_issuer }) }
            if ('buying_asset_type' in op) {
                assets.add({ asset_type: op.buying_asset_type, asset_code: op.buying_asset_code, asset_issuer: op.buying_asset_issuer })
            }
            if (('source_asset_type' in op)) { assets.add({ asset_type: op.source_asset_type, asset_code: op.source_asset_code, asset_issuer: op.source_asset_issuer }) }

        })

        assets = [...assets]
        if (assets.length)
            dispatch('assets/fetchAssetsMeta', assets, { root: true })
    }

}