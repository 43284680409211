export default {
    setNetworkType(state, networkType) {
        state.networkType = networkType
    },
    setApiUrl(state, apiUrl) {
        state.apiUrl = apiUrl
    },
    toggleDarkMode(state, color) {
        state.darkMode = color;
    }
}
