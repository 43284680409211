import http from '@/utils/axios';
import router from '@/router';

export default {
    fetchAccountById({ commit, rootGetters, dispatch }, accountId) {
        commit('resetState')
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .accounts()
            .accountId(accountId)
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .call()
            .then(async (response) => {
                commit('setAccount', response)
                dispatch('fetchAccountMeta', accountId);
                dispatch("charts/convertPrices", null, { root: true });

                let formattedAssets = [];
                response.balances.forEach(balance => {
                    if (!['native', "liquidity_pool_shares"].includes(balance.asset_type)) {
                        formattedAssets.push({
                            asset_type: balance.asset_type,
                            asset_code: balance.asset_code,
                            asset_issuer: balance.asset_issuer
                        })
                    }
                });
                dispatch('fetchAccountAssetsMeta', formattedAssets)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    formatAccountsFromTransactions(_, transactions) {
        const accounts = transactions.map(transaction => transaction.source_account);
        return [...new Set(accounts)]
    },
    formatAccountsFromOperations(_, operations) {
        const accounts = operations.flatMap(operation => {
            switch (operation.type) {
                case "create_account":
                    return [operation.source_account, operation.account, operation.funder ?? null]
                case "payment":
                    return [operation.source_account, operation.from, operation.to]
                case "account_merge":
                    return [operation.source_account, operation.account, operation.into]
                case "begin_sponsoring_future_reserves":
                    return [operation.source_account, operation.sponsored_id]
                case "end_sponsoring_future_reserves":
                    return [operation.source_account, operation.begin_sponsor]
                case "revoke_sponsorship":
                    return [operation.source_account,
                    operation.trustline_account_id ?? null,
                    operation.data_account_id ?? null,
                    operation.signer_account_id ?? null,
                    operation.account_id ?? null]
                case "clawback":
                    return [operation.source_account, operation.from]
                case "set_trust_line_flags":
                    return [operation.source_account, operation.trustor]
                case "path_payment_strict_receive":
                case "manage_sell_offer":
                case "create_passive_sell_offer":
                case "set_options":
                case "change_trust":
                case "allow_trust":
                case "inflation":
                case "manage_data":
                case "bump_sequence":
                case "manage_buy_offer":
                case "path_payment_strict_send":
                case "create_claimable_balance":
                case "claim_claimable_balance":
                case "clawback_claimable_balance":
                case "liquidity_pool_deposit":
                case "liquidity_pool_withdraw":
                default:
                    return [operation.source_account]
            }
        });
        return [...new Set(accounts)]
    },
    formatAccountsFromEffects(_, effects) {
        const accounts = effects.flatMap(effect => {
            switch (effect.type) {
                case 'account_created':
                case 'account_removed':
                case 'account_credited':
                case 'account_debited':
                case 'account_thresholds_updated':
                case 'account_home_domain_updated':
                case 'account_flags_updated':
                case 'account_inflation_destination_updated':
                case 'trade':
                case 'data_created':
                case 'data_removed':
                case 'data_updated':
                case 'claimable_balance_created':
                case 'claimable_balance_claimant_created':
                case 'claimable_balance_claimed':
                case 'claimable_balance_clawed_back':
                case 'liquidity_pool_deposited':
                case 'liquidity_pool_withdrew':
                case 'liquidity_pool_trade':
                case 'liquidity_pool_created':
                case 'liquidity_pool_removed':
                    return [effect.account]
                case 'signer_created':
                case 'signer_removed':
                case 'signer_updated':
                    return [effect.account, effect.public_key ?? null]
                case 'trustline_created':
                case 'trustline_removed':
                case 'trustline_updated':
                case 'trustline_authorized':
                case 'trustline_deauthorized':
                case 'trustline_flags_updated':
                    return [effect.account, effect.trustor ?? null]
                case 'sequence_bumped':
                case 'account_sponsorship_created':
                case 'account_sponsorship_updated':
                case 'account_sponsorship_removed':
                case 'trustline_sponsorship_created':
                case 'trustline_sponsorship_updated':
                case 'trustline_sponsorship_removed':
                case 'data_sponsorship_created':
                case 'data_sponsorship_updated':
                case 'data_sponsorship_removed':
                case 'claimable_balance_sponsorship_created':
                case 'claimable_balance_sponsorship_updated':
                case 'claimable_balance_sponsorship_removed':
                case 'signer_sponsorship_created':
                case 'signer_sponsorship_updated':
                case 'signer_sponsorship_removed':
                    return [effect.account, effect.sponsor ?? null, effect.former_sponsor ?? null, effect.new_sponsor ?? null]
                case 'liquidity_pool_revoked':
                default:
                    return []
            }

        });
        return [...new Set(accounts)]
    },
    formatAccountsFromOffers(_, offers) {
        const accounts = offers.map(offer => offer.seller);
        return [...new Set(accounts)]
    },
    formatAccountsFromTrades(_, trades) {
        const accounts = trades.flatMap(trade => [trade.base_account ?? null, trade.counter_account ?? null]
        );
        return [...new Set(accounts)]
    },
    fetchAccountsLabels({ commit }, payload) {
        if (payload.accounts.length) {
            commit('setLabels', { loading: true })
            http.get('accounts/labels', { params: { accounts: payload.accounts } })
                .then((res) => {
                    const accounts = res.data.data.reduce((obj, item) => ({ ...obj, [item.account]: item.label ? item : null }), {});
                    commit('setLabels', { data: accounts, append: payload.append })
                })
                .catch((error) => {
                    console.log(error)
                    commit('setLabels', { error: error })
                })
                .finally(() => {
                    commit('setLabels', { loading: false })
                });
        }
    },
    // Accounts adjacent data
    async fetchMoreTxnsForAccount({ getters, commit, dispatch },) {
        commit('transactions/setLoading', true, { root: true })
        commit('transactions/setError', null, { root: true })
        commit('accounts/setLoadingTabTransactions', true, { root: true })
        try {
            let transactions;
            if (getters.getAccount.next_transactions) {
                transactions = await getters.getAccount.next_transactions()
                commit('loadMoreTxnsForAccount', { transactions: transactions.records, next_transactions: transactions.records.length ? transactions.next : null })
                dispatch("accounts/formatAccountsFromTransactions", transactions.records, { root: true })
                    .then((accounts) => { dispatch("accounts/fetchAccountsLabels", { accounts, append: true }, { root: true }) })
                dispatch('transactions/decodeTxnsOperations', transactions, { root: true }).then(() => {
                    dispatch('transactions/formatTxnsValues', transactions.records, { root: true }).then((values) =>
                        dispatch("analytics/fetchTxnValue", { values, append: true }, { root: true }))
                })
            } else if (Object.keys(getters.getAccount).length !== 0
                && !getters.getAccount.transactions_r) {
                commit('resetTabs');
                transactions = await getters.getAccount.transactions({ order: 'desc', limit: process.env.VUE_APP_API_LIMIT });
                commit('loadTxnsForAccount', { transactions: transactions.records, next_transactions: transactions.records.length ? transactions.next : null })
                dispatch("accounts/formatAccountsFromTransactions", transactions.records, { root: true })
                    .then((accounts) => { dispatch("accounts/fetchAccountsLabels", { accounts, append: true }, { root: true }) })
                dispatch('transactions/decodeTxnsOperations', transactions, { root: true }).then(() => {
                    dispatch('transactions/formatTxnsValues', transactions.records, { root: true }).then((values) =>
                        dispatch("analytics/fetchTxnValue", { values, append: true }, { root: true }))
                })
            }
            if (!transactions || !transactions.records.length) {
                commit('setIsSlateEmpty', true)
            }
        } catch (error) {
            commit('transactions/setError', error, { root: true })
        } finally {
            commit('accounts/setLoadingTabTransactions', false, { root: true })
            commit('transactions/setLoading', false, { root: true })
        }
    },
    async fetchMoreOpsForAccount({ getters, commit, dispatch }, accountId) {
        commit('operations/setLoading', true, { root: true })
        commit('operations/setError', null, { root: true })
        try {
            let operations;
            if (getters.getAccount.next_operations) {
                operations = await getters.getAccount.next_operations()
                commit('loadMoreOpsForAccount', { operations: operations.records, next_operations: operations.records.length ? operations.next : null })
                const accounts = await dispatch("formatAccountsFromOperations", getters.getAccount.operations_r)
                await dispatch("accounts/fetchAccountsLabels", { accounts: [...accounts, accountId], append: true }, { root: true })
                await dispatch('operations/fetchOperationsAssetsMeta', getters.getAccount.operations_r, { root: true })
            } else if (Object.keys(getters.getAccount).length !== 0
                && !getters.getAccount.operations_r) {
                commit('resetTabs');
                operations = await getters.getAccount.operations({ order: 'desc', limit: process.env.VUE_APP_API_LIMIT})
                commit('loadOpsForAccount', { operations: operations.records, next_operations: operations.records.length ? operations.next : null })
                const accounts = await dispatch("formatAccountsFromOperations", getters.getAccount.operations_r)
                await dispatch("accounts/fetchAccountsLabels", { accounts: [...accounts, accountId], append: true }, { root: true })
                await dispatch('operations/fetchOperationsAssetsMeta', getters.getAccount.operations_r, { root: true })
            }
            if (!operations || !operations.records.length) {
                commit('setIsSlateEmpty', true)
            }
        } catch (error) {
            commit('operations/setError', error, { root: true })
        } finally {
            commit('operations/setLoading', false, { root: true })
        }
    },
    async fetchMorePaymentsForAccount({ getters, commit, dispatch }, accountId) {
        commit('payments/setLoading', true, { root: true })
        commit('payments/setError', null, { root: true })
        try {
            let payments;
            if (getters.getAccount.next_payments) {
                payments = await getters.getAccount.next_payments()
                commit('loadMorePaymentsForAccount', { payments: payments.records, next_payments: payments.records.length ? payments.next : null })
                const accounts = await dispatch("formatAccountsFromOperations", getters.getAccount.payments_r)
                await dispatch("accounts/fetchAccountsLabels", { accounts: [...accounts, accountId], append: true }, { root: true })
                await dispatch('operations/fetchOperationsAssetsMeta', getters.getAccount.payments_r, { root: true })
            } else if (Object.keys(getters.getAccount).length !== 0
                && !Array.isArray(getters.getAccount.payments_r)) {
                commit('resetTabs');
                payments = await getters.getAccount.payments({ order: 'desc', limit: process.env.VUE_APP_API_LIMIT})
                commit('loadPaymentsForAccount', { payments: payments.records, next_payments: payments.records.length ? payments.next : null })
                const accounts = await dispatch("formatAccountsFromOperations", getters.getAccount.payments_r)
                await dispatch("accounts/fetchAccountsLabels", { accounts: [...accounts, accountId], append: true }, { root: true })
                await dispatch('operations/fetchOperationsAssetsMeta', getters.getAccount.payments_r, { root: true })
            }
            if (!payments || !payments.records.length) {
                commit('setIsSlateEmpty', true)
            }
        } catch (error) {
            commit('payments/setError', error, { root: true })
        } finally {
            commit('payments/setLoading', false, { root: true })
        }

    },
    async fetchMoreEffectsForAccount({ getters, commit, dispatch }) {
        commit('effects/setLoading', true, { root: true })
        commit('effects/setError', null, { root: true })
        try {
            let effects;
            if (getters.getAccount.next_effects) {
                effects = await getters.getAccount.next_effects()
                const effAccs = await dispatch("accounts/formatAccountsFromEffects", effects.records, { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts: effAccs, append: true }, { root: true })
                commit('loadMoreEffectsForAccount', { effects: effects.records, next_effects: effects.records.length ? effects.next : null })
                await dispatch('effects/fetchEffectsAssetsMeta', getters.getAccount.effects_r, { root: true })
            } else if (Object.keys(getters.getAccount).length !== 0
                && !Array.isArray(getters.getAccount.effects_r)) {
                commit('resetTabs');
                effects = await getters.getAccount.effects({ order: 'desc', limit: process.env.VUE_APP_API_LIMIT})
                const effAccs = await dispatch("accounts/formatAccountsFromEffects", effects.records, { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts: effAccs, append: true }, { root: true })
                commit('loadEffectsForAccount', { effects: effects.records, next_effects: effects.records.length ? effects.next : null })
                await dispatch('effects/fetchEffectsAssetsMeta', getters.getAccount.effects_r, { root: true })
            }
            if (!effects || !effects.records.length) {
                commit('setIsSlateEmpty', true)
            }
        } catch (error) {
            commit('effects/setError', error, { root: true })
        } finally {
            commit('effects/setLoading', false, { root: true })
        }
    },
    async fetchMoreOffersForAccount({ getters, commit, dispatch }) {
        commit('offers/setLoading', true, { root: true })
        commit('offers/setError', null, { root: true })
        try {
            let offers;
            if (getters.getAccount.next_offers) {
                offers = await getters.getAccount.next_offers()
                const offAccs = await dispatch("accounts/formatAccountsFromOffers", offers.records, { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts: offAccs, append: true }, { root: true })
                commit('loadMoreOffersForAccount', { offers: offers.records, next_offers: offers.records.length ? offers.next : null })
                await dispatch('offers/fetchOffersAssetsMeta', getters.getAccount.offers_r, { root: true })
            } else if (Object.keys(getters.getAccount).length !== 0
                && !Array.isArray(getters.getAccount.offers_r)) {
                commit('resetTabs');
                offers = await getters.getAccount.offers({ order: 'desc' , limit: process.env.VUE_APP_API_LIMIT});
                const offAccs = await dispatch("accounts/formatAccountsFromOffers", offers.records, { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts: offAccs, append: true }, { root: true })
                commit('loadOffersForAccount', { offers: offers.records, next_offers: offers.records.length ? offers.next : null })
                await dispatch('offers/fetchOffersAssetsMeta', offers.records, { root: true })
            }
            if (!offers || !offers.records.length) {
                commit('setIsSlateEmpty', true)
            }
        } catch (error) {
            commit('offers/setError', error, { root: true })
        } finally {
            commit('offers/setLoading', false, { root: true })
        }
    },
    async fetchMoreTradesForAccount({ getters, commit, dispatch }) {
        commit('trades/setLoading', true, { root: true })
        commit('trades/setError', null, { root: true })
        try {
            let trades;
            if (getters.getAccount.next_trades) {
                trades = await getters.getAccount.next_trades()
                const tradeAccs = await dispatch("accounts/formatAccountsFromTrades", trades.records, { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts: tradeAccs, append: true }, { root: true })
                commit('loadMoreTradesForAccount', { trades: trades.records, next_trades: trades.records.length ? trades.next : null })
                await dispatch('trades/fetchTradesAssetsMeta', getters.getAccount.trades_r, { root: true })
            } else if (Object.keys(getters.getAccount).length !== 0
                && !Array.isArray(getters.getAccount.trades_r)) {
                commit('resetTabs');
                trades = await getters.getAccount.trades({ order: 'desc', limit: process.env.VUE_APP_API_LIMIT })
                const tradeAccs = await dispatch("accounts/formatAccountsFromTrades", trades.records, { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts: tradeAccs, append: true }, { root: true })
                commit('loadTradesForAccount', { trades: trades.records, next_trades: trades.records.length ? trades.next : null })
                await dispatch('trades/fetchTradesAssetsMeta', getters.getAccount.trades_r, { root: true })
            }
            if (!trades || !trades.records.length) {
                commit('setIsSlateEmpty', true)
            }
        } catch (error) {
            commit('trades/setError', error, { root: true })
        } finally {
            commit('trades/setLoading', false, { root: true })
        }
    },
    fetchTopAccounts({ commit }, payload) {
        commit('setTopAccounts', { loading: true })
        http.get('accounts/top', { params: { page: payload.page, paginate: payload.paginate } })
            .then(async (res) => {
                commit('setTopAccounts', {
                    data: res.data.data,
                    page: res.data.meta.current_page,
                    from: res.data.meta.from,
                    to: res.data.meta.to,
                    total: res.data.meta.total,
                    lastPage: res.data.meta.last_page
                })
            })
            .catch((error) => {
                console.log(error)
                commit('setTopAccounts', { error: error })
            })
            .finally(() => {
                commit('setTopAccounts', { loading: false })
            });
    },
    fetchDirectoryAccounts({ commit }, payload) {
        commit('setDirectoryAccounts', { loading: true })
        http.get('accounts', { params: { page: payload.page, labels: payload.labels, paginate: payload.paginate } })
            .then(async (res) => {
                commit('setDirectoryAccounts', {
                    data: res.data.data,
                    page: res.data.meta.current_page,
                    from: res.data.meta.from,
                    to: res.data.meta.to,
                    total: res.data.meta.total,
                    lastPage: res.data.meta.last_page
                })
            })
            .catch((error) => {
                console.log(error)
                commit('setDirectoryAccounts', { error: error })
            })
            .finally(() => {
                commit('setDirectoryAccounts', { loading: false })
            });
    },
    fetchAccountMeta({ commit }, accountId) {
        http.get(`accounts/${accountId}/show`)
            .then((res) => {
                commit('setAccountMeta', res.data.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    createOrder({ commit }, params) {
        commit('setOrder', { loading: true })
        http.post('orders/store', params)
            .then((res) => {
                router.push({ name: 'OrderRoute', params: { order_id: res.data.data.order_no } })
            })
            .catch((error) => {
                commit('setOrder', { error: error.response.data.message?.account?.[0] || error.response.data.message })
            })
            .finally(() => {
                commit('setOrder', { loading: false })
            });
    },
    showOrder({ commit }, order_id) {
        commit('setOrder', { loading: true })
        http.get(`orders/${order_id}/show`)
            .then((res) => {
                commit('setOrder', { data: res.data.data })
            })
            .catch((error) => {
                console.log(error)
                commit('setOrder', { error: error })
            })
            .finally(() => {
                commit('setOrder', { loading: false })
            });
    },
    // Fetching account assets meta
    fetchAccountAssetsMeta({ commit }, assets) {
        assets = assets.filter((asset) => !['native', 'liquidity_pool_shares'].includes(asset.asset_type))
        if (assets.length) {
            commit('setAccountAssetsMeta', { loading: true })
            http.post('assets/meta', { assets })
                .then((res) => {
                    const assets = res.data.data.reduce((obj, item) => ({ ...obj, [item.asset_code + '_' + item.asset_issuer]: item }), {});
                    commit('setAccountAssetsMeta', { data: assets })
                })
                .catch((error) => {
                    console.log(error)
                    commit('setAccountAssetsMeta', { error })
                })
                .finally(() => {
                    commit('setAccountAssetsMeta', { loading: false })
                });
        }
    },
    resetEmptySlate({ commit }) {
        commit('setIsSlateEmpty', false)
    }
}
