export default {
    setClaimableBalances(state, claimableBalances) {
        state.claimableBalances = claimableBalances
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },
    incrementCbsCount(state, claimableBalances) {
        state.claimableBalancesCount += claimableBalances
    },
    decrementCbsCount(state, claimableBalances) {
        state.claimableBalancesCount -= claimableBalances
    },
    resetPageNav(state) {
        state.claimableBalancesCount = 0
        state.pageEnd = false
    },
    setPageEnd(state, pageEnd) {
        state.pageEnd = pageEnd
    }
}