export default () => ({
    assets: {
        data: {},
        loading: true,
        error: null,
        page: 1,
        from: 0,
        to: 0,
        total: 0,
        lastPage: 0
    },
    assetsMeta: {
        data: {},
        loading: false,
        error: null,
    },

    // State for asset details
    selectedAsset: {
        data: {},
        loading: false,
        error: null,
    },
    assetChartsData: {
        data: [],
        loading: false,
        error: null,
    },
    orderBook: {},
    trades: {},
    aggregatedTrades: {
        records : [],
    },
    isStreamingTrades: false,
    isStreamingOrderBook: false,
    loadingCandles: false,
    latestPrice: {
        value: 0.0000
    },
})
