export default {
    getLatestContracts(state) {
        return "data" in state.contracts ? state.contracts.data : []
    },
    getLoadingStatus(state) {
        return state.loading
    },
    getLoadingContractVerifyStatus(state){
        return state.loadingContractVerify
    },
    getStatusContractVerify(state){
        return state.statusContractVerify
    },
    getError(state) {
        return state.error
    },
    getSelectedContract(state) {
        return state.selectedContract
    },
    disablePrevBtn(state, getters) {

        return state.pageStart || state.error || state.loading || state.streaming || !getters.getLatestContracts.length || state.contractsCount <= process.env.VUE_APP_API_LIMIT
    },
    disableNextBtn(state, getters) {

        return state.error || state.loading || state.streaming || !getters.getLatestContracts.length || state.pageEnd
    },
    getStreamIntervalId(state) {
        return state.streamIntervalId
    },
    isStreaming(state) {
        return state.streaming
    }
}
