export default () => ({
    offers: {},
    loading: false,
    error: null,

    // Used for page switching
    offersCount: 0,
    pageEnd: false,

    // State for streaming offers
    streaming: false,
    streamIntervalId: null
})
