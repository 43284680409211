export default {
    getLatestTrades(state) {
        return "records" in state.trades ? state.trades.records : []
    },
    getLoadingStatus(state) {
        return state.loading
    },
    getError(state) {
        return state.error
    },
    disablePrevBtn(state, getters) {

        return state.error || state.loading || state.streaming || !getters.getLatestTrades.length || state.tradesCount <= process.env.VUE_APP_API_LIMIT
    },
    disableNextBtn(state, getters) {

        return state.error || state.loading || state.streaming || !getters.getLatestTrades.length || state.pageEnd
    },
    getStreamIntervalId(state) {
        return state.streamIntervalId
    },
    isStreaming(state) {
        return state.streaming
    },
}
