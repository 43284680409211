import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { getDefaultState } from "./state";


export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state: getDefaultState()
}