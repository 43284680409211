export default () => ({
    financialData: {},
    blockchainData: {},
    loading: false,
    error: null,
    conversionPrices: {
        data: {},
        loading: false,
        error: null,
    },
})
