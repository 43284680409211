import { getDefaultState } from './state'

export default {
    setAccount(state, account) {
        state.account = account
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },
    setAccountAssetsMeta(state, accountAssetsMeta) {
        state.accountAssetsMeta = Object.assign(state.accountAssetsMeta, accountAssetsMeta)
    },
    setAccountMeta(state, meta) {
        if (state.account) { state.account.meta = meta }
    },
    setLabels(state, payload) {
        if (payload.append && payload.data) {
            state.labels = Object.assign(state.labels, { data: { ...state.labels.data, ...payload.data } })
        } else {
            state.labels = Object.assign(state.labels, payload)
        }
    },
    setLoadingTabTransactions(state, loading) {
        state.loadingTabTransactions = loading
    },
    loadTxnsForAccount(state, payload) {
        state.account.transactions_r = payload.transactions
        state.account.next_transactions = payload.next_transactions
    },
    loadMoreTxnsForAccount(state, payload) {
        state.account.transactions_r = state.account.transactions_r.concat(payload.transactions)
        state.account.next_transactions = payload.next_transactions
    },
    loadOpsForAccount(state, payload) {
        state.account.operations_r = payload.operations
        state.account.next_operations = payload.next_operations
    },
    loadMoreOpsForAccount(state, payload) {
        state.account.operations_r = state.account.operations_r.concat(payload.operations)
        state.account.next_operations = payload.next_operations
    },
    loadPaymentsForAccount(state, payload) {
        state.account.payments_r = payload.payments
        state.account.next_payments = payload.next_payments
    },
    loadMorePaymentsForAccount(state, payload) {
        state.account.payments_r = state.account.payments_r.concat(payload.payments)
        state.account.next_payments = payload.next_payments
    },
    loadEffectsForAccount(state, payload) {
        state.account.effects_r = payload.effects
        state.account.next_effects = payload.next_effects
    },
    loadMoreEffectsForAccount(state, payload) {
        state.account.effects_r = state.account.effects_r.concat(payload.effects)
        state.account.next_effects = payload.next_effects
    },
    loadOffersForAccount(state, payload) {
        state.account.offers_r = payload.offers
        state.account.next_offers = payload.next_offers
    },
    loadMoreOffersForAccount(state, payload) {
        state.account.offers_r = state.account.offers_r.concat(payload.offers)
        state.account.next_offers = payload.next_offers
    },
    loadTradesForAccount(state, payload) {
        state.account.trades_r = payload.trades
        state.account.next_trades = payload.next_trades
    },
    loadMoreTradesForAccount(state, payload) {
        state.account.trades_r = state.account.trades_r.concat(payload.trades)
        state.account.next_trades = payload.next_trades
    },
    setTopAccounts(state, topAccounts) {
        state.topAccounts = Object.assign(state.topAccounts, topAccounts)
    },
    setDirectoryAccounts(state, directoryAccounts) {
        state.directoryAccounts = Object.assign(state.directoryAccounts, directoryAccounts)
    },
    setOrder(state, order) {
        state.order = Object.assign(state.order, order)
    },
    setIsSlateEmpty(state, isSlateEmpty) {
        state.isSlateEmpty = isSlateEmpty
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    resetTabs(state) {
        if (state.account) {
            state.account.transactions_r = null
            state.account.operations_r = null
            state.account.payments_r = null
            state.account.effects_r = null
            state.account.offers_r = null
            state.account.trades_r = null

            state.account.next_transactions = null
            state.account.next_operations = null
            state.account.next_payments = null
            state.account.next_effects = null
            state.account.next_offers = null
            state.account.next_trades = null
        }
    }
}
