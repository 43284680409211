import { publicServer, testServer, futureNet, customServer } from '@/utils/stellar-sdk.js'
import { NetworksEnum } from './state'
export default {
    getNetworkType(state) {
        return state.networkType
    },
    getApiServer(state) {
        switch (state.networkType) {
            case NetworksEnum.liveNet:
                return publicServer
            case NetworksEnum.testNet:
                return testServer
            case NetworksEnum.futureNet:
                return futureNet
            case NetworksEnum.customNet:
                return customServer(state.apiUrl)
            default:
                return null
        }

    },
    getThemeColor(state) {
        return state.darkMode
    },
}
