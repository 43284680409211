export default {
    setPosts(state, posts) {
        state.posts = posts
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },
}