export default {
    getAssetStats(state) {
        return state.assetStats
    },
    getExtendedAssetStats(state) {
        return state.extendedAssetStats
    },
    getBlockchainStats(state) {
        return state.blockchainStats
    },
    getExtendedBlockchainStats(state) {
        return state.extendedBlockchainStats
    },
    getTxnValue(state) {
        return state.txnValue
    },
    getLedgerValue(state) {
        return state.ledgerValue
    },
}