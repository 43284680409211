import http from '@/utils/axios'; import axios from "axios"
import * as StellarSdk from 'stellar-sdk';
const { Asset, SorobanRpc, xdr, StrKey, Networks} = StellarSdk;
import {get} from 'lodash';

export default {
    // Fetching assets and switching pages
    fetchAssets({ commit }, payload) {
        commit('setAssets', { loading: true })
        commit('setLoading', true)
        return http.get('assets', { params: { page: payload.page, paginate: payload.paginate, sort: payload.sort, code: payload.code } })
            .then(async (res) => {
                commit('setAssets', {
                    data: res.data.data,
                    page: res.data.meta.current_page,
                    from: res.data.meta.from,
                    to: res.data.meta.to,
                    total: res.data.meta.total,
                    lastPage: res.data.meta.last_page
                })
                return res.data
            })
            .catch((error) => {
                console.log(error)
                commit('setAssets', { error: error })
                return false
            })
            .finally(() => {
                commit('setLoading', false)
                commit('setAssets', { loading: false })
            });
    },
    // Fetching assets meta
    fetchAssetsMeta({ commit }, assets) {
        assets = assets.filter((asset) => !['native', 'liquidity_pool_shares'].includes(asset.asset_type))
        if (assets.length) {
            commit('setAssetsMeta', { loading: true })
            http.post('assets/meta', { assets })
                .then((res) => {
                    const assets = res.data.data.reduce((obj, item) => ({ ...obj, [item.asset_code + '_' + item.asset_issuer]: item }), {});
                    commit('setAssetsMeta', { data: assets })
                })
                .catch((error) => {
                    console.log(error)
                    commit('setAssetsMeta', { error })
                })
                .finally(() => {
                    commit('setAssetsMeta', { loading: false })
                });
        }
    },
    // Fetching asset by asset code
    fetchAssetByCodeAndIssuer({ commit, rootGetters, dispatch }, asset) {
        if (asset.asset_issuer !== 'native') {
            commit('setSelectedAsset', { loading: true })
            rootGetters['settings/getApiServer']
                .assets()
                .forCode(asset.asset_code)
                .forIssuer(asset.asset_issuer)
                .call()
                .then((response) => {
                    commit('setSelectedAsset', { data: response.records[0] })
                    dispatch('fetchAssetMeta', { asset_code: asset.asset_code, asset_issuer: asset.asset_issuer })
                })
                .catch((error) => {
                    console.log(error)
                    commit('setSelectedAsset', { error })
                })
                .finally(() => {
                    commit('setSelectedAsset', { loading: false })
                });
        } else {
            commit('setSelectedAsset', {
                data: {
                    asset_code: 'XLM',
                    asset_issuer: 'Stellar Development Foundation'
                }
            })
            dispatch('fetchAssetMeta', { asset_code: asset.asset_code, asset_issuer: asset.asset_issuer })
        }
    },
    fetchAssetMeta({ commit }, payload) {
        http.get(`assets/${payload.asset_code}-${payload.asset_issuer}/show`)
            .then((res) => {
                commit('setAssetMeta', res.data.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    fetchAssetChartsData({ commit }, payload) {
        commit('setAssetChartsData', { loading: true })
        http.get(`assets/${payload.assetCode}-${payload.assetIssuer}/graphs`, {
            params: {
                graph: payload.graph
            }
        })
            .then((res) => {
                commit('setAssetChartsData', { data: res.data.data })
            })
            .catch((error) => {
                console.log(error)
                commit('setAssetChartsData', { error })
            })
            .finally(() => {
                commit('setAssetChartsData', { loading: false })
            });
    },
    async fetchMoreOpsForAsset({ commit },) {
        commit('operations/setLoading', true, { root: true })
        commit('operations/setError', null, { root: true })
        try {
            axios.get(`https://api.stellar.expert/explorer/public/asset/BOFA-GD4DDG26LBZRGJB4HEDJUTAAPJ2POP42XC4RVKOF6YTKMWSEI5SZQUSA`).then((res) => {
            }).catch((err) => {
                console.log(err)
            })

            // if (getters.getAccount.next_operations) {
            //     const operations = await getters.getAccount.next_operations()
            //     commit('loadMoreOpsForAccount', { operations: operations.records, next_operations: operations.records.length ? operations.next : null })
            //     const accounts = await dispatch("formatAccountsFromOperations", getters.getAccount.operations_r)
            //     await dispatch("accounts/fetchAccountsLabels", { accounts: [...accounts, accountId], append: true }, { root: true })
            //     await dispatch('operations/fetchOperationsAssetsMeta', getters.getAccount.operations_r, { root: true })
            // } else if (Object.keys(getters.getAccount).length !== 0
            //     && !getters.getAccount.operations_r) {
            //     commit('resetTabs');
            //     const operations = await getters.getAccount.operations({ order: 'desc' })
            //     commit('loadOpsForAccount', { operations: operations.records, next_operations: operations.records.length ? operations.next : null })
            //     const accounts = await dispatch("formatAccountsFromOperations", getters.getAccount.operations_r)
            //     await dispatch("accounts/fetchAccountsLabels", { accounts: [...accounts, accountId], append: true }, { root: true })
            //     await dispatch('operations/fetchOperationsAssetsMeta', getters.getAccount.operations_r, { root: true })
            // }
        } catch (error) {
            console.log(error)
            commit('operations/setError', error, { root: true })
        } finally {
            commit('operations/setLoading', false, { root: true })
        }
    },
    fetchAssetLiquidityPools({ commit, rootGetters}, payload) {
        let asset = Asset.native();
        if(payload.asset_issuer !== 'native'){
            asset = new Asset(payload.asset_code, payload.asset_issuer);
        }
        rootGetters['settings/getApiServer']
            .liquidityPools()
            .forAssets(asset, Asset.native())
            .limit(200)
            .order('desc')
            .call()
            .then((response) => {
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            });
    },
    fetchAssetOffers({ commit, rootGetters}, payload) {
        let asset = Asset.native();
        if(payload.asset_issuer !== 'native'){
            asset = new Asset(payload.asset_code, payload.asset_issuer);
        }
        rootGetters['settings/getApiServer']
            .offers()
            .selling(Asset.native())
            .buying(asset)
            .limit(200)
            .order('desc')
            .call()
            .then((response) => {
            })
            .catch((error) => {
            })
            .finally(() => {
            });
    },
    fetchAssetTrades({ commit, rootGetters}, payload) {
        let asset = Asset.native();
        if(payload.asset_issuer !== 'native'){
            asset = new Asset(payload.asset_code, payload.asset_issuer);
        }
        rootGetters['settings/getApiServer']
            .trades()
            .forAssetPair(asset, Asset.native())
            .limit(200)
            .order('desc')
            .call()
            .then((response) => {
                commit('setTrades', response)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            });
    },
    fetchAssetOrderBook({ commit, rootGetters}, payload) {
        if (payload.asset_issuer) {
            let asset = Asset.native();
            if(payload.asset_issuer !== 'native'){
                asset = new Asset(payload.asset_code, payload.asset_issuer);
            }
            rootGetters['settings/getApiServer']
                .orderbook(asset, Asset.native())
                .limit(200)
                .call()
                .then((response) => {
                    commit('setOrderBook', response)
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
            });
        }
    },
    fetchAssetTradesAggregated({ commit, rootGetters}, payload) {
        let asset = Asset.native();
        if(payload.assetIssuer !== 'native'){
            asset = new Asset(payload.assetCode, payload.assetIssuer);
        }
        let offset = 0
        if (payload.resolution > 3600000){
            offset = 3600000
        }
        commit('setLoadingCandles', true)
        rootGetters['settings/getApiServer']
            .tradeAggregation(asset, Asset.native(), 0, 0,  payload.resolution, 0)
            .order('desc')
            .limit(200)
            .cursor('now')
            .call()
            .then((response) => {
                commit('setAssetTradesAggregated', response)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                commit('setLoadingCandles', false)
            });
    },
    fetchAssetTradesAggregatedHistory({ commit, rootGetters}, payload) {
        let asset = new Asset(payload.assetCode, payload.assetIssuer);
        commit('setLoadingCandles', true)
        let endTime = payload.startTime 
        rootGetters['settings/getApiServer']
            .tradeAggregation(asset, Asset.native(), 
                0,
                endTime*1000,  
                payload.resolution, 
                0 
            )
            .order('desc')
            .limit(200)
            .call()
            .then((response) => {
                commit('setMoreAssetTradesAggregated', response)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                commit('setLoadingCandles', false)
            });
    },
    async startStreamingTrades({ getters, rootGetters, commit, dispatch }, payload) {
        const ledgerHandler = (response) => {
            commit('addStreamTrade', response)
        }
        const errorHandler = (error) => {
            commit('setStreamingTrades', false)
        }
        if (!getters.isStreamingTrades) {
            commit('setStreamingTrades', true)
            let asset = new Asset(payload.asset_code, payload.asset_issuer);
            rootGetters['settings/getApiServer']
                .trades()
                .forAssetPair(asset, Asset.native())
                .cursor('now')
                .stream({
                    onmessage: ledgerHandler,
                    onerror: errorHandler
                })
        }
    },
    async startStreamingOrderbook({ getters, rootGetters, commit, dispatch }, payload) {
        const ledgerHandler = (response) => {
            commit('setOrderBook', response)
        }
        const errorHandler = (error) => {
            commit('setStreamingOrderBook', false)
        }
        if (!getters.isStreamingOrderBook) {
            {
                commit('setStreamingOrderBook', true)
                let asset = new Asset(payload.asset_code, payload.asset_issuer);
                rootGetters['settings/getApiServer']
                    .orderbook(asset, Asset.native())
                    .limit(200)
                    .cursor('now')
                    .stream({
                        onmessage: ledgerHandler,
                        onerror: errorHandler
                    })
            }
        }
    },
    async getApiLatestXlmPrice({ getters, rootGetters, commit, dispatch }){
        return http.get('stats/asset/latest-price')
            .then(async (res) => {
                commit('setLatestXlmPrice', res.data);
                return res.data
            })
            .catch((error) => {
                console.log(error)
                return false
            })
            .finally(() => {
            });
    },
    async fetchSAC({commit, rootGetters, dispatch}, asset) {
        commit('setLoading', true)
        let contractAddress = new Asset(asset.asset_code, asset.asset_issuer)
        let contractAddressId = contractAddress.contractId(Networks.PUBLIC)
        let contractAddressHash = StrKey.decodeContract(contractAddressId)
        const server = new SorobanRpc.Server('https://cold-cool-feather.stellar-mainnet.quiknode.pro/ac437525c5a1c071fc5f53ea68a589827e07432c/');
        const contractCodeKey = xdr.LedgerKey.contractData(
            new xdr.LedgerKeyContractData({
              contract: xdr.ScAddress.scAddressTypeContract(contractAddressHash),
              durability: xdr.ContractDataDurability.persistent(),
              key: new xdr.ScVal.scvLedgerKeyContractInstance(),
            }),
        );

        let contractResponse = await server.getLedgerEntries(contractCodeKey).then(async function (response) {
            const ledgerEntry = get(response.entries, '0');
            if (!ledgerEntry) {
                return null;
            }
            return ledgerEntry;
        });

        return contractResponse
    },
}
