export default {
    // Fetching liquidity pools and switching pages
    fetchLiquidityPools({ commit, rootGetters, dispatch }) {
        commit('resetPageNav')
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .liquidityPools()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .call()
            .then((response) => {
                commit('setLiquidityPools', response)

                let formattedAssets = []
                response.records.forEach(liq_pool => {

                    const ast = liq_pool.reserves.map(item => {
                        const arr = item.asset.split(":");
                        if (item.asset !== 'native') {
                            return {
                                asset_type: arr[0].length > 4 ? 'credit_alphanum12' : 'credit_alphanum4',
                                asset_code: arr[0],
                                asset_issuer: arr[1]
                            }
                        }
                    })
                    formattedAssets = formattedAssets.concat(ast)
                });
                formattedAssets = formattedAssets.filter(n => n)
                dispatch('assets/fetchAssetsMeta', formattedAssets, { root: true })
                commit('incrementLpsCount', response.records.length)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchNextPage({ state, commit, rootGetters }) {
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .liquidityPools()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .cursor(state.liquidityPools.records[state.liquidityPools.records.length - 1].paging_token)
            .call()
            .then((response) => {
                if (response.records.length) {
                    commit('setLiquidityPools', response)
                    commit('incrementLpsCount', response.records.length)
                } else {
                    commit('setPageEnd', true)
                }
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });

    },
    fetchPrevPage({ commit, state, rootGetters }) {
        commit('setLoading', true)
        commit('decrementLpsCount', state.liquidityPools.records.length)
        rootGetters['settings/getApiServer']
            .liquidityPools()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("asc")
            .cursor(state.liquidityPools.records[0].paging_token)
            .call()
            .then((response) => {
                response.records = response.records.reverse()
                commit('setLiquidityPools', response)
                commit('setPageEnd', false)
            })
            .catch((error) => {
                console.log(error)
                commit('incrementLpsCount', state.liquidityPools.records.length)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchLiquidityPool({ commit, rootGetters, dispatch }, payload) {
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .liquidityPools()
            .liquidityPoolId(payload)
            .call()
            .then((response) => {
                commit('setLiquidityPool', response)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchLiquidityPoolTransactions({ getters, commit, rootGetters, dispatch }, payload) {
        commit('setLoadingTab', true)
        rootGetters['settings/getApiServer']
            .transactions()
            .order("desc")
            .forLiquidityPool(payload)
            .call()
            .then((response) => {
                commit('setLiquidityPoolTransactions', response)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoadingTab', false)
            });
    },
    fetchMoreLiquidityPoolTransactions({ getters, commit, rootGetters, dispatch }, payload) {
        if(getters.getLiquidityPoolTransactionsNext){
            commit('setLoadingTab', true)
            getters.getLiquidityPoolTransactionsNext().then((response) => {
                    if (!response.records.length) {
                        response.next = null
                    }
                    commit('setMoreLiquidityPoolTransactions', response) 
                })
                .catch((error) => {
                    console.log(error)
                    commit('setError', error)
                })
                .finally(() => {
                    commit('setLoadingTab', false)
                });
        }
    },
    fetchLiquidityPoolOperations({ commit, rootGetters, dispatch }, payload) {
        commit('setLoadingTab', true)
        rootGetters['settings/getApiServer']
            .operations()
            .order("desc")
            .forLiquidityPool(payload)
            .call()
            .then((response) => {
                commit('setLiquidityPoolOperations', response)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoadingTab', false)
            });
    },
    fetchMoreLiquidityPoolOperations({ getters, commit, rootGetters, dispatch }, payload) {
        if(getters.getLiquidityPoolOperationsNext){
            commit('setLoadingTab', true)
            getters.getLiquidityPoolOperationsNext().then((response) => {
                    if (!response.records.length) {
                        response.next = null
                    }
                    commit('setMoreLiquidityPoolOperations', response) 
                })
                .catch((error) => {
                    console.log(error)
                    commit('setError', error)
                })
                .finally(() => {
                    commit('setLoadingTab', false)
                });
        }
    },
    fetchLiquidityPoolEffects({ commit, rootGetters, dispatch }, payload) {
        commit('setLoadingTab', true)
        rootGetters['settings/getApiServer']
            .effects()
            .order("desc")
            .forLiquidityPool(payload)
            .call()
            .then((response) => {
                commit('setLiquidityPoolEffects', response)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoadingTab', false)
            });
    },
    fetchMoreLiquidityPoolEffects({ getters, commit, rootGetters, dispatch }, payload) {
        if(getters.getLiquidityPoolEffectsNext){
            commit('setLoadingTab', true)
            getters.getLiquidityPoolEffectsNext().then((response) => {
                    if (!response.records.length) {
                        response.next = null
                    }
                    commit('setMoreLiquidityPoolEffects', response) 
                })
                .catch((error) => {
                    console.log(error)
                    commit('setError', error)
                })
                .finally(() => {
                    commit('setLoadingTab', false)
                });
        }
    },
    fetchLiquidityPoolTrades({ commit, rootGetters, dispatch }, payload) {
        commit('setLoadingTab', true)
        rootGetters['settings/getApiServer']
            .trades()
            .order("desc")
            .forLiquidityPool(payload)
            .call()
            .then((response) => {
                commit('setLiquidityPoolTrades', response)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoadingTab', false)
            });
    },
    fetchMoreLiquidityPoolTrades({ getters, commit, rootGetters, dispatch }, payload) {
        if(getters.getLiquidityPoolTradesNext){
            commit('setLoadingTab', true)
            getters.getLiquidityPoolTradesNext().then((response) => {
                    if (!response.records.length) {
                        response.next = null
                    }
                    commit('setMoreLiquidityPoolTrades', response) 
                })
                .catch((error) => {
                    console.log(error)
                    commit('setError', error)
                })
                .finally(() => {
                    commit('setLoadingTab', false)
                });
        }
    }
}
