// import http from '@/utils/axios';
export default {
    // Fetching assets and switching pages
    fetchPosts({ commit, }) {
        const blogPosts = {
            "blog-post-1": {
                title: "Blog Post 1",
                author: "Stefan Oros",
                content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Viverra suspendisse potenti nullam ac tortor vitae purus. Urna neque viverra justo nec ultrices dui. Ut porttitor leo a diam sollicitudin. Donec adipiscing tristique risus nec feugiat in fermentum. Viverra adipiscing at in tellus integer. Elit scelerisque mauris pellentesque pulvinar pellentesque habitant. Imperdiet dui accumsan sit amet nulla facilisi morbi tempus. Augue neque gravida in fermentum et sollicitudin ac. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Non consectetur a erat nam at lectus urna duis. Non consectetur a erat nam at lectus. Ut placerat orci nulla pellentesque dignissim enim. Est ullamcorper eget nulla facilisi etiam dignissim diam quis enim.
      Praesent elementum facilisis leo vel fringilla est ullamcorper eget nulla. Neque sodales ut etiam sit. Condimentum vitae sapien pellentesque habitant morbi tristique senectus et netus. Scelerisque fermentum dui faucibus in ornare quam viverra orci. Nunc non blandit massa enim nec dui nunc mattis enim. Tellus in hac habitasse platea dictumst vestibulum rhoncus est pellentesque. Et magnis dis parturient montes nascetur ridiculus mus mauris. Erat velit scelerisque in dictum non consectetur a erat nam. Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Aliquet porttitor lacus luctus accumsan tortor posuere ac. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere ac.
      
      Cras ornare arcu dui vivamus. Diam phasellus vestibulum lorem sed risus ultricies tristique nulla. Felis bibendum ut tristique et egestas quis ipsum. At ultrices mi tempus imperdiet nulla malesuada pellentesque. Pellentesque massa placerat duis ultricies lacus sed turpis. Nulla facilisi etiam dignissim diam quis enim. Luctus accumsan tortor posuere ac ut consequat semper viverra. Risus pretium quam vulputate dignissim. Sit amet risus nullam eget felis eget nunc lobortis mattis. Facilisi morbi tempus iaculis urna id volutpat. Varius duis at consectetur lorem donec massa. Mattis molestie a iaculis at. Risus at ultrices mi tempus imperdiet nulla malesuada pellentesque. Vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Tristique risus nec feugiat in fermentum. Et netus et malesuada fames ac turpis egestas sed tempus. Diam volutpat commodo sed egestas egestas.
      
      Mi sit amet mauris commodo quis imperdiet massa tincidunt nunc. Tortor dignissim convallis aenean et tortor at. Cras adipiscing enim eu turpis egestas pretium aenean pharetra. Curabitur vitae nunc sed velit. Facilisis volutpat est velit egestas dui id. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Natoque penatibus et magnis dis. Ut consequat semper viverra nam libero justo. Nullam vehicula ipsum a arcu cursus vitae. In ante metus dictum at. Blandit cursus risus at ultrices mi tempus. Urna neque viverra justo nec ultrices dui. Libero enim sed faucibus turpis in eu mi bibendum.
      
      Ac turpis egestas integer eget aliquet nibh praesent tristique magna. In fermentum posuere urna nec. Nibh tortor id aliquet lectus proin nibh nisl condimentum id. Sagittis aliquam malesuada bibendum arcu. Non consectetur a erat nam at lectus urna duis. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Tellus id interdum velit laoreet id. Integer malesuada nunc vel risus. Amet nisl purus in mollis nunc sed id. Id neque aliquam vestibulum morbi blandit cursus risus. Pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet. Vel quam elementum pulvinar etiam non quam lacus. At ultrices mi tempus imperdiet nulla malesuada.`,
                imgSrc: require("@/assets/images/placeholder.jpeg"),
                slug: "blog-post-1",
                date: "2021-02-04",
            },
            "blog-post-2": {
                title: "Blog Post 2",
                author: "Stefan Oros",
                content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Viverra suspendisse potenti nullam ac tortor vitae purus. Urna neque viverra justo nec ultrices dui. Ut porttitor leo a diam sollicitudin. Donec adipiscing tristique risus nec feugiat in fermentum. Viverra adipiscing at in tellus integer. Elit scelerisque mauris pellentesque pulvinar pellentesque habitant. Imperdiet dui accumsan sit amet nulla facilisi morbi tempus. Augue neque gravida in fermentum et sollicitudin ac. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Non consectetur a erat nam at lectus urna duis. Non consectetur a erat nam at lectus. Ut placerat orci nulla pellentesque dignissim enim. Est ullamcorper eget nulla facilisi etiam dignissim diam quis enim.
      Praesent elementum facilisis leo vel fringilla est ullamcorper eget nulla. Neque sodales ut etiam sit. Condimentum vitae sapien pellentesque habitant morbi tristique senectus et netus. Scelerisque fermentum dui faucibus in ornare quam viverra orci. Nunc non blandit massa enim nec dui nunc mattis enim. Tellus in hac habitasse platea dictumst vestibulum rhoncus est pellentesque. Et magnis dis parturient montes nascetur ridiculus mus mauris. Erat velit scelerisque in dictum non consectetur a erat nam. Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Aliquet porttitor lacus luctus accumsan tortor posuere ac. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere ac.
      
      Cras ornare arcu dui vivamus. Diam phasellus vestibulum lorem sed risus ultricies tristique nulla. Felis bibendum ut tristique et egestas quis ipsum. At ultrices mi tempus imperdiet nulla malesuada pellentesque. Pellentesque massa placerat duis ultricies lacus sed turpis. Nulla facilisi etiam dignissim diam quis enim. Luctus accumsan tortor posuere ac ut consequat semper viverra. Risus pretium quam vulputate dignissim. Sit amet risus nullam eget felis eget nunc lobortis mattis. Facilisi morbi tempus iaculis urna id volutpat. Varius duis at consectetur lorem donec massa. Mattis molestie a iaculis at. Risus at ultrices mi tempus imperdiet nulla malesuada pellentesque. Vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Tristique risus nec feugiat in fermentum. Et netus et malesuada fames ac turpis egestas sed tempus. Diam volutpat commodo sed egestas egestas.
      
      Mi sit amet mauris commodo quis imperdiet massa tincidunt nunc. Tortor dignissim convallis aenean et tortor at. Cras adipiscing enim eu turpis egestas pretium aenean pharetra. Curabitur vitae nunc sed velit. Facilisis volutpat est velit egestas dui id. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Natoque penatibus et magnis dis. Ut consequat semper viverra nam libero justo. Nullam vehicula ipsum a arcu cursus vitae. In ante metus dictum at. Blandit cursus risus at ultrices mi tempus. Urna neque viverra justo nec ultrices dui. Libero enim sed faucibus turpis in eu mi bibendum.
      
      Ac turpis egestas integer eget aliquet nibh praesent tristique magna. In fermentum posuere urna nec. Nibh tortor id aliquet lectus proin nibh nisl condimentum id. Sagittis aliquam malesuada bibendum arcu. Non consectetur a erat nam at lectus urna duis. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Tellus id interdum velit laoreet id. Integer malesuada nunc vel risus. Amet nisl purus in mollis nunc sed id. Id neque aliquam vestibulum morbi blandit cursus risus. Pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet. Vel quam elementum pulvinar etiam non quam lacus. At ultrices mi tempus imperdiet nulla malesuada.`,
                imgSrc: require("@/assets/images/placeholder.jpeg"),
                slug: "blog-post-2",
                date: "2022-02-04",
            },
            "blog-post-3": {
                title: "Blog Post 3",
                author: "Stefan Oros",
                content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Viverra suspendisse potenti nullam ac tortor vitae purus. Urna neque viverra justo nec ultrices dui. Ut porttitor leo a diam sollicitudin. Donec adipiscing tristique risus nec feugiat in fermentum. Viverra adipiscing at in tellus integer. Elit scelerisque mauris pellentesque pulvinar pellentesque habitant. Imperdiet dui accumsan sit amet nulla facilisi morbi tempus. Augue neque gravida in fermentum et sollicitudin ac. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Non consectetur a erat nam at lectus urna duis. Non consectetur a erat nam at lectus. Ut placerat orci nulla pellentesque dignissim enim. Est ullamcorper eget nulla facilisi etiam dignissim diam quis enim.
      Praesent elementum facilisis leo vel fringilla est ullamcorper eget nulla. Neque sodales ut etiam sit. Condimentum vitae sapien pellentesque habitant morbi tristique senectus et netus. Scelerisque fermentum dui faucibus in ornare quam viverra orci. Nunc non blandit massa enim nec dui nunc mattis enim. Tellus in hac habitasse platea dictumst vestibulum rhoncus est pellentesque. Et magnis dis parturient montes nascetur ridiculus mus mauris. Erat velit scelerisque in dictum non consectetur a erat nam. Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Aliquet porttitor lacus luctus accumsan tortor posuere ac. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere ac.
      
      Cras ornare arcu dui vivamus. Diam phasellus vestibulum lorem sed risus ultricies tristique nulla. Felis bibendum ut tristique et egestas quis ipsum. At ultrices mi tempus imperdiet nulla malesuada pellentesque. Pellentesque massa placerat duis ultricies lacus sed turpis. Nulla facilisi etiam dignissim diam quis enim. Luctus accumsan tortor posuere ac ut consequat semper viverra. Risus pretium quam vulputate dignissim. Sit amet risus nullam eget felis eget nunc lobortis mattis. Facilisi morbi tempus iaculis urna id volutpat. Varius duis at consectetur lorem donec massa. Mattis molestie a iaculis at. Risus at ultrices mi tempus imperdiet nulla malesuada pellentesque. Vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Tristique risus nec feugiat in fermentum. Et netus et malesuada fames ac turpis egestas sed tempus. Diam volutpat commodo sed egestas egestas.
      
      Mi sit amet mauris commodo quis imperdiet massa tincidunt nunc. Tortor dignissim convallis aenean et tortor at. Cras adipiscing enim eu turpis egestas pretium aenean pharetra. Curabitur vitae nunc sed velit. Facilisis volutpat est velit egestas dui id. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Natoque penatibus et magnis dis. Ut consequat semper viverra nam libero justo. Nullam vehicula ipsum a arcu cursus vitae. In ante metus dictum at. Blandit cursus risus at ultrices mi tempus. Urna neque viverra justo nec ultrices dui. Libero enim sed faucibus turpis in eu mi bibendum.
      
      Ac turpis egestas integer eget aliquet nibh praesent tristique magna. In fermentum posuere urna nec. Nibh tortor id aliquet lectus proin nibh nisl condimentum id. Sagittis aliquam malesuada bibendum arcu. Non consectetur a erat nam at lectus urna duis. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Tellus id interdum velit laoreet id. Integer malesuada nunc vel risus. Amet nisl purus in mollis nunc sed id. Id neque aliquam vestibulum morbi blandit cursus risus. Pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet. Vel quam elementum pulvinar etiam non quam lacus. At ultrices mi tempus imperdiet nulla malesuada.`,
                imgSrc: require("@/assets/images/placeholder.jpeg"),
                slug: "blog-post-3",
                date: "2022-03-04",
            },
        };

        commit('setLoading', true)
        commit('setPosts', blogPosts)
        commit('setLoading', false)
        // http.get('blogs')
        //     .then((res) => {
        //         commit('setPosts', { data: res.data.data })
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //         commit('setPosts', { error: error })
        //     })
        //     .finally(() => {
        //         commit('setPosts', { loading: false })
        //     });
    },
}
