export default () => ({
    liquidityPools: {},
    loading: false,
    loadingTab: false,
    error: null,

    // Used for page switching
    liquidityPoolsCount: 0,
    pageEnd: false,
    liquidityPool: {},
    liquidityPoolTrades: {},
    liquidityPoolTransactions: {},
    liquidityPoolEffects: {},
    liquidityPoolOperations: {},
})
