export default {
    setAssetStats(state, assetStats) {
        state.assetStats = Object.assign(state.assetStats, assetStats)
    },
    setExtendedAssetStats(state, extendedAssetStats) {
        state.extendedAssetStats = Object.assign(state.extendedAssetStats, extendedAssetStats)
    },
    setBlockchainStats(state, blockchainStats) {
        state.blockchainStats = Object.assign(state.blockchainStats, blockchainStats)
    },
    setExtendedBlockchainStats(state, extendedBlockchainStats) {
        state.extendedBlockchainStats = Object.assign(state.extendedBlockchainStats, extendedBlockchainStats)
    },
    setTxnValue(state, payload) {
        if (payload.append && payload.data) {
            state.txnValue = Object.assign(state.txnValue, { data: { ...state.txnValue.data, ...payload.data } })
        } else {
            state.txnValue = Object.assign(state.txnValue, payload)
        }
    },
    setLedgerValue(state, ledgerValue) {
        state.ledgerValue = Object.assign(state.ledgerValue, ledgerValue)
    },
}
