export default {
    setContracts(state, contracts) {
        state.contracts = contracts 
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setLoadingContractVerify(state, loading){
        state.loadingContractVerify = loading
    },
    setStatusContractVerify(state, loading){
        state.statusContractVerify= loading
    },
    setError(state, error) {
        state.error = error
    },
    incrementContractsCount(state, contracts) {
        state.contractsCount += contracts 
    },
    decrementTxnCount(state, contracts) {
        state.contractsCount -= contracts 
    },
    resetPageNav(state) {
        state.contractsCount = 0
        state.pageEnd = false
    },
    setSelectedContract(state, selectedContract) {
        state.selectedContract = selectedContract
    },
    setPageEnd(state, pageEnd) {
        state.pageEnd = pageEnd
    },
    setPageStart(state, pageStart) {
        state.pageStart = pageStart 
    },
    setStreaming(state, streaming) {
        state.streaming = streaming
    },
    setStreamIntervalId(state, streamIntervalId) {
        state.streamIntervalId = streamIntervalId
    },
    loadMoreOpsForTxn(state, payload) {
        state.selectedContract.operations = state.selectedContract.operations.concat(payload.operations)
        state.selectedContract.next_operations = payload.next_operations
    }
}
