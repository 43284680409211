export default {
    getAccount(state) {
        return state.account
    },
    getLoadingTabTransactions(state) {
        return state.loadingTabTransactions
    },
    getLoadingStatus(state) {
        return state.loading
    },
    getError(state) {
        return state.error
    },
    getAccountAssetsMeta(state) {
        return state.accountAssetsMeta
    },
    getLabels(state) {
        return state.labels
    },
    getTopAccounts(state) {
        return state.topAccounts
    },
    getDirectoryAccounts(state) {
        return state.directoryAccounts
    },
    getOrder(state) {
        return state.order
    },
    getIsSlateEmpty(state) {
        return state.isSlateEmpty
    },
}
