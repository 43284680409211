export default {
    setOffers(state, offers) {
        state.offers = offers
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },
    incrementOffersCount(state, offers) {
        state.offersCount += offers
    },
    decrementOffersCount(state, offers) {
        state.offersCount -= offers
    },
    resetPageNav(state) {
        state.offersCount = 0
        state.pageEnd = false
    },
    setPageEnd(state, pageEnd) {
        state.pageEnd = pageEnd
    },
    setStreaming(state, streaming) {
        state.streaming = streaming
    },
    setStreamIntervalId(state, streamIntervalId) {
        state.streamIntervalId = streamIntervalId
    },
}