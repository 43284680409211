
export default () => ({
    assetStats: {
        data: {},
        loading: false,
        error: null,
    },
    extendedAssetStats: {
        data: {},
        loading: false,
        error: null,
    },
    blockchainStats: {
        data: {},
        loading: false,
        error: null,
    },
    extendedBlockchainStats: {
        data: {},
        loading: false,
        error: null,
    },
    txnValue: {
        data: {},
        loading: false,
        error: null,
    },
    ledgerValue: {
        data: {},
        loading: false,
        error: null,
    },
})