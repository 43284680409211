export default () => ({
    payments: {},
    loading: false,
    error: null,

    // Used for page switching
    paymentsCount: 0,
    pageEnd: false

})
