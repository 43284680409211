<template>
  <router-view />
</template>
<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { NetworksEnum } from "@/store/modules/settings/state";
import { getCookieValue } from '@/utils/cookies'

export default defineComponent({
  name: "App",
  setup() {
    const darkMode = getCookieValue('darkMode');
    const store = useStore();
    const systemDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (systemDarkTheme && darkMode == null) {
      document.documentElement.setAttribute('data-bs-theme', 'dark');
      store.dispatch('settings/changeColorTheme', true);
    }

    if (darkMode !== null) {
       const themes = {true: 'dark', false: 'light'}
       document.documentElement.setAttribute('data-bs-theme', themes[darkMode]);
       const darkModeBool = darkMode == 'true';
       store.dispatch('settings/changeColorTheme', darkModeBool);
    }

    if (localStorage.getItem("custom_net") === null) {
      if (window.location.origin.includes(process.env.VUE_APP_TESTNET_URL)) {
        store.dispatch("settings/changeNetworkType", {
          networkType: NetworksEnum.testNet,
        });
      } else if (
        window.location.origin.includes(process.env.VUE_APP_LIVENET_URL)
      ) {
        store.dispatch("settings/changeNetworkType", {
          networkType: NetworksEnum.liveNet,
        });
      } else {
        store.dispatch("settings/changeNetworkType", {
          networkType: NetworksEnum.futureNet,
        });
      }
    } else
      store.dispatch("settings/changeNetworkType", {
        networkType: NetworksEnum.customNet,
        customNet: localStorage.getItem("custom_net"),
      });
  },
});
</script>
