export default {
    getLatestLiquidityPools(state) {
        return "records" in state.liquidityPools ? state.liquidityPools.records : []
    },
    getLiquidityPool(state) {
        return state.liquidityPool
    },
    getLiquidityPoolTransactions(state) {
        return "records" in state.liquidityPoolTransactions ? state.liquidityPoolTransactions.records : []
    },
    getLiquidityPoolTransactionsNext(state) {
        return state.liquidityPoolTransactions.next
    },
    getLiquidityPoolOperations(state) {
        return "records" in state.liquidityPoolOperations ? state.liquidityPoolOperations.records : []
    },
    getLiquidityPoolOperationsNext(state) {
        return state.liquidityPoolOperations.next
    },
    getLiquidityPoolEffects(state) {
        return "records" in state.liquidityPoolEffects ? state.liquidityPoolEffects.records : []
    },
    getLiquidityPoolEffectsNext(state) {
        return state.liquidityPoolEffects.next
    },
    getLiquidityPoolTrades(state) {
        return "records" in state.liquidityPoolTrades ? state.liquidityPoolTrades.records : []
    },
    getLiquidityPoolTradesNext(state) {
        return state.liquidityPoolTrades.next
    },
    getLoadingStatus(state) {
        return state.loading
    },
    getLoadingTabStatus(state) {
        return state.loadingTab
    },
    getError(state) {
        return state.error
    },
    disablePrevBtn(state, getters) {
        return state.error || state.loading || !getters.getLatestLiquidityPools.length || state.liquidityPoolsCount <= process.env.VUE_APP_API_LIMIT
    },
    disableNextBtn(state, getters) {
        return state.error || state.loading || !getters.getLatestLiquidityPools.length || state.pageEnd
    },
}
