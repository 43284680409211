export default {
    getPosts(state) {
        return state.posts
    },
    getLoadingStatus(state) {
        return state.loading
    },
    getError(state) {
        return state.error
    },
}
