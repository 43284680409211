import { createStore } from 'vuex'
import ledgers from './modules/ledgers/index'
import transactions from './modules/transactions/index'
import contracts from './modules/contracts/index'
import operations from './modules/operations/index'
import accounts from './modules/accounts/index'
import assets from './modules/assets/index'
import payments from './modules/payments/index'
import offers from './modules/offers/index'
import settings from './modules/settings/index'
import trades from './modules/trades/index'
import effects from './modules/effects/index'
import claimable_balances from './modules/claimable_balances/index'
import liquidity_pools from './modules/liquidity_pools/index'
import charts from './modules/charts/index'
import analytics from './modules/analytics/index'
import blog from './modules/blog/index'
const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    ledgers,
    transactions,
    contracts,
    operations,
    settings,
    accounts,
    assets,
    payments,
    offers,
    trades,
    effects,
    claimable_balances,
    liquidity_pools,
    charts,
    analytics,
    blog
  },
  strict: debug,

})

