
export const getDefaultState = () => ({
    account: {},
    loadingTabTransactions: false,
    loading: false,
    error: null,
    isSlateEmpty: false,
    accountAssetsMeta: {
        data: {},
        loading: false,
        error: null
    },
    labels: {
        data: {},
        loading: false,
        error: null,
    },
    topAccounts: {
        data: {},
        loading: false,
        error: null,
        page: 1,
        from: 0,
        to: 0,
        total: 0,
        lastPage: 0
    },
    directoryAccounts: {
        data: {},
        loading: false,
        error: null,
        page: 1,
        from: 0,
        to: 0,
        total: 0,
        lastPage: 0
    },
    order: {
        data: {},
        loading: false,
        error: null,
    },
})
