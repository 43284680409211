
export default () => ({
    ledgers: {},
    loading: false,
    error: null,

    // Used for page navigation
    ledgersCount: 0,

    // State for ledger details
    selectedLedger: {},
    loadingTab: false,

    // State for streaming ledgers
    streaming: false,
    stopStreamFunc: null,
    ledgerEffects: {},
    ledgerOperations: {},
    ledgerPayments: {}
})
