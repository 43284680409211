export default {
    getLatestPayments(state) {
        return "records" in state.payments ? state.payments.records : []
    },
    getLoadingStatus(state) {
        return state.loading
    },
    getError(state) {
        return state.error
    },
    disablePrevBtn(state, getters) {

        return state.error || state.loading || !getters.getLatestPayments.length || state.paymentsCount <= process.env.VUE_APP_API_LIMIT
    },
    disableNextBtn(state, getters) {

        return state.error || state.loading || !getters.getLatestPayments.length || state.pageEnd
    },
}
