export default {
    // Fetching trades and switching pages
    fetchTrades({ commit, rootGetters, dispatch }) {
        commit('resetPageNav')
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .trades()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .call()
            .then(async (response) => {
                commit('setTrades', response)
                commit('incrementTradesCount', response.records.length)
                const accounts = await dispatch("accounts/formatAccountsFromTrades", response.records, { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts }, { root: true })
                dispatch('fetchTradesAssetsMeta', response.records)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchNextPage({ state, commit, rootGetters, dispatch }) {
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .trades()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .cursor(state.trades.records[state.trades.records.length - 1].paging_token)
            .call()
            .then(async (response) => {
                if (response.records.length) {
                    commit('setTrades', response)
                    commit('incrementTradesCount', response.records.length)
                    const accounts = await dispatch("accounts/formatAccountsFromTrades", response.records, { root: true })
                    await dispatch("accounts/fetchAccountsLabels", { accounts }, { root: true })
                    dispatch('fetchTradesAssetsMeta', response.records)
                } else {
                    commit('setPageEnd', true)
                }
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });

    },
    fetchPrevPage({ commit, state, rootGetters, dispatch }) {
        commit('setLoading', true)
        commit('decrementTradesCount', state.trades.records.length)
        rootGetters['settings/getApiServer']
            .trades()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("asc")
            .cursor(state.trades.records[0].paging_token)
            .call()
            .then(async (response) => {
                response.records = response.records.reverse()
                commit('setTrades', response)
                commit('setPageEnd', false)
                const accounts = await dispatch("accounts/formatAccountsFromTrades", response.records, { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts }, { root: true })
                dispatch('fetchTradesAssetsMeta', response.records)
            })
            .catch((error) => {
                console.log(error)
                commit('incrementTradesCount', state.trades.records.length)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    // Fetch assets meta for offers that have assets
    fetchTradesAssetsMeta({ dispatch }, trades) {
        let assets = new Set()
        trades.forEach((trade) => {
            assets.add({ asset_type: trade.base_asset_type, asset_code: trade.base_asset_code, asset_issuer: trade.base_asset_issuer })
            assets.add({ asset_type: trade.counter_asset_type, asset_code: trade.counter_asset_code, asset_issuer: trade.counter_asset_issuer })
        })
        assets = [...assets]
        if (assets.length)
            dispatch('assets/fetchAssetsMeta', assets, { root: true })
    },
    // Start/stop streaming trades
    async startStreaming({ rootGetters, commit, dispatch }) {
        await dispatch("fetchTrades", process.env.VUE_APP_API_LIMIT);
        commit('setStreaming', true)
        const stop = setInterval(() => {
            rootGetters['settings/getApiServer']
                .trades()
                .limit(process.env.VUE_APP_API_LIMIT)
                .order("desc")
                .call()
                .then(async (response) => {
                    console.log(response)
                    commit('setTrades', response);
                    const accounts = await dispatch("accounts/formatAccountsFromTrades", response.records, { root: true })
                    await dispatch("accounts/fetchAccountsLabels", { accounts }, { root: true })
                    dispatch('fetchTradesAssetsMeta', response.records)
                })
                .catch((error) => {
                    console.log(error)
                    commit('setError', error)
                    commit('setStreaming', false)
                    commit('setStreamIntervalId', null)
                })
        }, 5000)
        commit('setStreamIntervalId', stop)
    },
    stopStreaming({ commit, getters }) {
        clearInterval(getters.getStreamIntervalId)
        commit('setStreaming', false)
        commit('setStreamIntervalId', null)
    },


}
