import * as StellarSdk from 'stellar-sdk';
import { NetworksEnum } from "@/store/modules/settings/state";

const {Horizon, xdr} = StellarSdk;

export const publicServer = new Horizon.Server(process.env.VUE_APP_PUBLIC_NETWORK)
export const testServer = new Horizon.Server(process.env.VUE_APP_TEST_NETWORK)
export const futureNet = new Horizon.Server(process.env.VUE_APP_FUTURE_NETWORK)
export const customServer = (url) => new Horizon.Server(url)
export const decodeTxnXdr = (xdr_encoded) => xdr.TransactionEnvelope.fromXDR(xdr_encoded, 'base64').value().tx()
export const decodeTxnSorobanXdr = (xdr_soroban_encoded) => xdr.TransactionEnvelope.fromXDR(xdr_soroban_encoded, 'base64')


const network = (networkType) => {
      switch (networkType.value) {
        case NetworksEnum.liveNet:
          return t("navbar.livenet");
        case NetworksEnum.testNet:
          return t("navbar.testnet");
        case NetworksEnum.futureNet:
          return t("navbar.futurenet");  
        case NetworksEnum.customNet:
          return t("navbar.customnet");
      }
};
