const routes = [
    {
        path: '/',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/pages/Index.vue')
            }
        ],
        meta: {
            breadcrumb: 'Homepage'
        }
    },
    {
        path: '/ledgers',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/pages/resources/Ledgers.vue'),
                name: 'LedgersRoute',
            },
            {
                path: ':ledger_sequence',
                component: () => import('@/pages/resources_details/LedgerDetails.vue'),
                name: 'LedgerDetailsRoute',
            },
        ],
        meta: {
            breadcrumb: 'Ledgers'
        }
    },
    {
        path: '/transactions',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/pages/resources/Transactions.vue'),
                name: 'TransactionsRoute',
            },
            {
                path: ':txn_hash',
                component: () => import('@/pages/resources_details/TxnDetails.vue'),
                name: 'TxnDetailsRoute',
            }
        ],
        meta: {
            breadcrumb: 'Transactions'
        }
    },
    {
        path: '/contracts',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/pages/resources/Contracts.vue'),
                name: 'ContractsRoute',
            },
            {
                path: ':contract_id',
                component: () => import('@/pages/resources_details/ContractDetails.vue'),
                name: 'ContractDetailsRoute',
            }
        ],
        meta: {
            breadcrumb: 'Contracts'
        }
    },
    {
        path: '/operations',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/pages/resources/Operations.vue'),
                name: 'OperationsRoute',
            },
            {
                path: '/operations/:op_id',
                component: () => import('@/pages/resources_details/OperationDetails.vue'),
                name: 'OpDetailsRoute',
            }
        ],
        meta: {
            breadcrumb: 'Operations'
        }
    },
    {
        path: '/accounts',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/pages/resources/accounts/TopAccounts.vue'),
                name: 'TopAccountsRoute',
            },
            {
                path: ':acc_id',
                component: () => import('@/pages/resources_details/AccountDetails.vue'),
                name: 'AccountDetailsRoute'
            },
            {
                path: 'directory',
                component: () => import('@/layouts/DirectoryLayout.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('@/pages/resources/accounts/Directory.vue'),
                        name: 'DirectoryRoute',

                    },
                    {
                        path: 'update',
                        component: () => import('@/pages/resources/accounts/DirectoryUpdate.vue'),
                        name: 'DirectoryUpdateRoute',
                        meta: {
                            breadcrumb: 'Update'
                        }
                    },
                    {
                        path: 'order/:order_id',
                        component: () => import('@/pages/resources/accounts/Order.vue'),
                        name: 'OrderRoute',
                    }
                ],
                meta: {
                    breadcrumb: 'Directory'
                }
            }
        ],
        meta: {
            breadcrumb: 'Accounts'
        }

    },
    {
        path: '/assets',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/pages/resources/Assets.vue'),
                name: 'AssetsRoute',
            },
            {
                path: ':asset_code-:asset_issuer',
                component: () => import('@/pages/resources_details/AssetDetails.vue'),
                name: 'AssetDetailsRoute',
            }
        ],
        meta: {
            breadcrumb: 'Assets'
        }
    },
    {
        path: '/markets',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/pages/resources/Markets.vue'),
                name: 'MarketsRoute',
            },
            {
                path: ':asset_code-:asset_issuer',
                component: () => import('@/pages/resources_details/AssetDetails.vue'),
                name: 'MarketAssetsRoute',
            }
        ],
        meta: {
            breadcrumb: 'Markets'
        }
    },
    // {
    //     path: '/payments',
    //     component: () => import('@/layouts/MainLayout.vue'),
    //     children: [
    //         {
    //             path: '', component: () => import('@/pages/resources/Payments.vue'),
    //             name: 'PaymentsRoute',
    //         }
    //     ],
    //     meta: {
    //         breadcrumb: 'Payments'
    //     }
    // },
    // {
    //     path: '/offers',
    //     component: () => import('@/layouts/MainLayout.vue'),
    //     children: [
    //         {
    //             path: '', component: () => import('@/pages/resources/Offers.vue'),
    //             name: 'OffersRoute',
    //         }
    //     ],
    //     meta: {
    //         breadcrumb: 'Offers'
    //     }
    // },
    // {
    //     path: '/trades',
    //     component: () => import('@/layouts/MainLayout.vue'),
    //     children: [
    //         {
    //             path: '', component: () => import('@/pages/resources/Trades.vue'),
    //             name: 'TradesRoute',
    //         }
    //     ],
    //     meta: {
    //         breadcrumb: 'Trades'
    //     }
    // },
    {
        path: '/effects',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            // {
            //     path: '', component: () => import('@/pages/resources/Effects.vue'),
            //     name: 'EffectsRoute',
            // },
            {
                path: ':eff_id',
                component: () => import('@/pages/resources_details/EffectDetails.vue'),
                name: 'EffectDetailsRoute',
            }
        ],
        meta: {
            breadcrumb: 'Effects'
        }
    },
    // {
    //     path: '/claimable_balances',
    //     component: () => import('@/layouts/MainLayout.vue'),
    //     children: [
    //         {
    //             path: '', component: () => import('@/pages/resources/ClaimableBalances.vue'),
    //             name: 'ClaimableBalancesRoute',
    //         }
    //     ],
    //     meta: {
    //         breadcrumb: 'Claimable Balances'
    //     }
    // },
    {
        path: '/liquidity-pools',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '', component: () => import('@/pages/resources/LiquidityPools.vue'),
                name: 'LiquidityPoolsRoute',
            },
            {
                path: '/liquidity-pool/:pool_id',
                component: () => import('@/pages/resources_details/LiquidityPoolDetails.vue'),
                name: 'LiquidityPoolDetailsRoute',
            }
        ],
        meta: {
            breadcrumb: 'Liquidity Pools'
        }
    },
    // {
    //     path: '/privacy',
    //     component: () => import('@/layouts/MainLayout.vue'),
    //     children: [
    //         {
    //             path: '', component: () => import('@/pages/Privacy.vue'),
    //             name: 'PrivacyRoute',
    //         }
    //     ],
    //     meta: {
    //         breadcrumb: 'Privacy'
    //     }
    // },
    // {
    //     path: '/support',
    //     component: () => import('@/layouts/MainLayout.vue'),
    //     children: [
    //         {
    //             path: '', component: () => import('@/pages/Support.vue'),
    //             name: 'SupportRoute',
    //         }
    //     ],
    //     meta: {
    //         breadcrumb: 'Support'
    //     }
    // },
    // {
    //     path: '/terms',
    //     component: () => import('@/layouts/MainLayout.vue'),
    //     children: [
    //         {
    //             path: '', component: () => import('@/pages/Terms.vue'),
    //             name: 'TermsRoute',
    //         }
    //     ],
    //     meta: {
    //         breadcrumb: 'Terms'
    //     }
    // },
    // {
    //     path: '/markets',
    //     component: () => import('@/layouts/MainLayout.vue'),
    //     children: [
    //         {
    //             path: '', component: () => import('@/pages/resources/Markets.vue'),
    //             name: 'MarketsRoute',
    //         }
    //     ],
    //     meta: {
    //         breadcrumb: 'Markets'
    //     }
    // },
    {
        path: '/price/:chartId?',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '', component: () => import('@/pages/resources/charts/PriceCharts.vue'),
                name: 'PriceRoute',
            }
        ],
        meta: {
            breadcrumb: 'Price'
        }
    },
    {
        path: '/charts/:chartId?',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '', component: () => import('@/pages/resources/charts/LedgerDetailsCharts.vue'),
                name: 'LedgerDetailsChartsRoute',
            }
        ],
        meta: {
            breadcrumb: 'Charts'
        }
    },
    {
        path: '/analytics/:chartId?',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '', component: () => import('@/pages/resources/charts/NetworkDetailsCharts.vue'),
                name: 'AnalyticsRoute',
            }
        ],
        meta: {
            breadcrumb: 'Analytics'
        }
    },
    // {
    //     path: '/blog',
    //     component: () => import('@/layouts/MainLayout.vue'),
    //     children: [
    //         {
    //             path: '', component: () => import('@/pages/resources/more/blog/Blog.vue'),
    //             name: 'BlogRoute',
    //         },
    //         {
    //             path: ':slug', component: () => import('@/pages/resources/more/blog/BlogPost.vue'),
    //             name: 'BlogPostRoute',
    //             props: true
    //         }
    //     ],
    //     meta: {
    //         breadcrumb: 'Blog'
    //     }
    // },
    // {
    //     path: '/donate',
    //     component: () => import('@/layouts/MainLayout.vue'),
    //     children: [
    //         {
    //             path: '', component: () => import('@/pages/resources/more/Donate.vue'),
    //             name: 'DonateRoute',
    //         }
    //     ],
    //     meta: {
    //         breadcrumb: 'Donate'
    //     }
    // },
    // {
    //     path: '/news',
    //     component: () => import('@/layouts/MainLayout.vue'),
    //     children: [
    //         {
    //             path: '', component: () => import('@/pages/resources/more/News.vue'),
    //             name: 'NewsRoute',
    //         }
    //     ],
    //     meta: {
    //         breadcrumb: 'News'
    //     }
    // },
    // {
    //     path: '/roadmap',
    //     component: () => import('@/layouts/MainLayout.vue'),
    //     children: [
    //         {
    //             path: '', component: () => import('@/pages/resources/more/Roadmap.vue'),
    //             name: 'RoadmapRoute',
    //         }
    //     ],
    //     meta: {
    //         breadcrumb: 'Roadmap'
    //     }
    // },
    {
        path: '/ledger/:ledger_sequence',
        redirect: { name: 'LedgerDetailsRoute' }
    },
    {
        path: '/tx/:txn_hash',
        redirect: { name: 'TxnDetailsRoute' }
    },
    {
        path: '/address/:acc_id',
        redirect: { name: 'AccountDetailsRoute' }
    },
    {
        path: '/404',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [{
            path:'',
            component: () => import('@/pages/Error404.vue'),
            name: 'Error404Route',
        }],
    },
    {
        path: '/:catchAll(.*)*',
        redirect: '/404'
    }
]

export default routes
