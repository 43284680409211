import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/assets/i18n/index.js'
import { createApp } from 'vue'
import LoadScript from "vue-plugin-load-script";
import { Tooltip, Collapse} from 'bootstrap';
import './assets/css/global.css';
import './assets/custom.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import 'bootstrap-icons/font/bootstrap-icons.css'

const app = createApp(App)

app.config.globalProperties.$emits = ['file-clicked']; 

app.use(LoadScript);
app.directive('tooltip', {
    mounted(el) {
        new Tooltip(el)
    },
    beforeUnmount(el) {
        Tooltip.getInstance(el).dispose()
    }
})
app.directive('datepicker', {
    mounted(el) {
        console.log(el)
        el.datepicker()
    },
})

app.directive('collapse', {
    mounted(el){
       new Collapse(el) 
    }
})

app.directive('middle-ellipsis', (el, binding) => {
    const str = binding.value.text
    if (str.length > binding.value.size * 2) {
        el.innerText = str.substr(0, binding.value.size) + '...' + str.substr(str.length - binding.value.size, str.length);
    } else
        el.innerText = str
})

app.use(store)
    .use(router)
    .use(i18n)
    .mount('#app')
