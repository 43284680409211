export default {
    setLedgers(state, ledgers) {
        state.ledgers = ledgers
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setLoadingTab(state, loading) {
        state.loadingTab = loading
    },
    setError(state, error) {
        state.error = error
    },
    incrementLedgersCount(state, ledgers) {
        state.ledgersCount += ledgers
    },
    decrementLedgersCount(state, ledgers) {
        state.ledgersCount -= ledgers
    },
    resetLedgersCount(state) {
        state.ledgersCount = 0
    },
    setSelectedLedger(state, selectedLedger) {
        state.selectedLedger = selectedLedger
    },
    setStreaming(state, streaming) {
        state.streaming = streaming
    },
    setStopStreamFunc(state, stopStreamFunc) {
        state.stopStreamFunc = stopStreamFunc
    },
    addStreamedLedger(state, ledger) {
        state.ledgers.records.unshift(ledger)
        state.ledgers.records.pop()
    },
    loadMoreTxnsForLedger(state, payload) {
        state.selectedLedger.transactions = state.selectedLedger.transactions.concat(payload.transactions)
        state.selectedLedger.next_transactions = payload.next_transactions
    },
    setLedgerEffects(state, effects) {
        state.ledgerEffects = effects
    },
    setMoreLedgerEffects(state, effects) {
        state.ledgerEffects.records = state.ledgerEffects.records.concat(effects.records)
        state.ledgerEffects.next = effects.next
    },
    setLedgerOperations(state, operations) {
        state.ledgerOperations = operations
    },
    setMoreLedgerOperations(state, operations) {
        state.ledgerOperations.records = state.ledgerOperations.records.concat(operations.records)
        state.ledgerOperations.next = operations.next
    },
    setLedgerPayments(state, payments) {
        state.ledgerPayments = payments
    },
    setMoreLedgerPayments(state, payments) {
        state.ledgerPayments.records = state.ledgerPayments.records.concat(payments.records)
        state.ledgerPayments.next = payments.next
    },
}
