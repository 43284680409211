import { NetworksEnum } from "./state"
import http from '@/utils/axios';

export default {
    changeNetworkType({ commit, rootGetters }, payload) {
        commit('setNetworkType', payload.networkType)
        switch (rootGetters['settings/getNetworkType']) {
            case NetworksEnum.liveNet:
                commit('setApiUrl', process.env.VUE_APP_PUBLIC_NETWORK)
                break
            case NetworksEnum.testNet:
                commit('setApiUrl', process.env.VUE_APP_TEST_NETWORK)
                break
            case NetworksEnum.futureNet:
                commit('setApiUrl', process.env.VUE_APP_FUTURE_NETWORK)
                break
            case NetworksEnum.customNet:
                commit('setApiUrl', payload.customNet)
                break
        }
    },
    changeColorTheme( { commit }, payload ){
        commit('toggleDarkMode', payload);
    },
    search({ commit }, payload) {
        return http.get('search', { params: { search: payload.search } }).then((res) => {
            return res
        })
    }
}
