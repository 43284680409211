export default {
    fetchEffects({ commit, rootGetters, dispatch }) {
        commit('resetPageNav')
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .effects()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .cursor('now')
            .call()
            .then(async (response) => {
                await dispatch('fetchEffectsOperations', response)
                commit('setEffects', response)
                commit('incrementEffectsCount', response.records.length)
                dispatch('effects/fetchEffectsAssetsMeta', response.records, { root: true })
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchNextPage({ state, commit, rootGetters, dispatch }) {
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .effects()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .cursor(state.effects.records[state.effects.records.length - 1].paging_token)
            .call()
            .then(async (response) => {
                if (response.records.length) {
                    await dispatch('fetchEffectsOperations', response)
                    commit('setEffects', response)
                    commit('incrementEffectsCount', response.records.length)
                    dispatch('effects/fetchEffectsAssetsMeta', response.records, { root: true })
                } else {
                    commit('setPageEnd', true)
                }
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchPrevPage({ commit, state, rootGetters, dispatch }) {
        commit('setLoading', true)
        commit('decrementEffectsCount', state.effects.records.length)
        rootGetters['settings/getApiServer']
            .effects()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("asc")
            .cursor(state.effects.records[0].paging_token)
            .call()
            .then(async (response) => {
                response.records = response.records.reverse()
                await dispatch('fetchEffectsOperations', response)
                commit('setEffects', response)
                commit('setPageEnd', false)
                dispatch('effects/fetchEffectsAssetsMeta', response.records, { root: true })
            })
            .catch((error) => {
                console.log(error)
                commit('incrementEffectsCount', state.effects.records.length)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    // Fetching effect by effect id
    fetchEffectById({ commit, dispatch, rootGetters }, effId) {
        effId = effId.split("-")
        commit('resetState')
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .effects()
            .forOperation(effId[0])
            .limit(200)
            .call()
            .then(async (response) => {
                const effect = response.records.find(effect => effect.id.split("-")[1] === effId[1])
                const effAcc = await dispatch("accounts/formatAccountsFromEffects", [effect], { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts: effAcc }, { root: true })
                commit('setSelectedEff', effect)
                dispatch('effects/fetchEffectsAssetsMeta', [effect], { root: true })
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    async fetchEffectsOperations({ commit }, effects) {
        await Promise.all(effects.records.map(async (effect) => {
            try {
                effect.op = await effect.operation()
            }
            catch (error) {
                commit('setError', error)
            }
        }));
    },
    async startStreaming({ rootGetters, commit, dispatch }) {
        await dispatch("fetchEffects", process.env.VUE_APP_API_LIMIT);
        commit('setStreaming', true)
        const stop = setInterval(() => {
            rootGetters['settings/getApiServer']
                .effects()
                .limit(process.env.VUE_APP_API_LIMIT)
                .order("desc")
                .call()
                .then(async (response) => {
                    await dispatch('fetchEffectsOperations', response)
                    commit('setEffects', response)
                    commit('incrementEffectsCount', response.records.length)
                    dispatch('effects/fetchEffectsAssetsMeta', response.records, { root: true })
                })
                .catch((error) => {
                    console.log(error)
                    commit('setError', error)
                    commit('setStreaming', false)
                    commit('setStreamIntervalId', null)
                })
        }, 5000)
        commit('setStreamIntervalId', stop)
    },
    stopStreaming({ commit, getters }) {
        clearInterval(getters.getStreamIntervalId)
        commit('setStreaming', false)
        commit('setStreamIntervalId', null)
    },
    fetchEffectsAssetsMeta({ dispatch }, effects) {
        let assets = new Set()
        effects.forEach((eff) => {
            switch (true) {
                case ('asset' in eff): {
                    eff.asset !== 'native' ? assets.add({ asset_type: eff.asset.split(":")[0].length <= 4 ? 'credit_alphanum4' : 'credit_alphanum12', asset_code: eff.asset.split(":")[0], asset_issuer: eff.asset.split(":")[1] }) :
                        assets.add({ asset_type: eff.asset, asset_code: null, asset_issuer: null });
                    break;
                }
                case ('asset_type' in eff &&
                    'asset_code' in eff &&
                    'asset_issuer' in eff &&
                    'liquidity_pool_shares' !== eff.asset_type):
                    assets.add({ asset_type: eff.asset_type, asset_code: eff.asset_code, asset_issuer: eff.asset_issuer })
                    break
                case ('bought_asset_type' in eff):
                    assets.add({ asset_type: eff.bought_asset_type, asset_code: eff.bought_asset_code, asset_issuer: eff.bought_asset_issuer })
                    break
                case ('sold_asset_type' in eff):
                    assets.add({ asset_type: eff.sold_asset_type, asset_code: eff.sold_asset_code, asset_issuer: eff.sold_asset_issuer })
                    break
            }
        })
        assets = [...assets]

        if (assets.length)
            dispatch('assets/fetchAssetsMeta', assets, { root: true })
    }
}