export default {
    setTrades(state, trades) {
        state.trades = trades
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },
    incrementTradesCount(state, trades) {
        state.tradesCount += trades
    },
    decrementTradesCount(state, trades) {
        state.tradesCount -= trades
    },
    resetPageNav(state) {
        state.tradesCount = 0
        state.pageEnd = false
    },
    setPageEnd(state, pageEnd) {
        state.pageEnd = pageEnd
    },
    setStreaming(state, streaming) {
        state.streaming = streaming
    },
    setStreamIntervalId(state, streamIntervalId) {
        state.streamIntervalId = streamIntervalId
    },
}