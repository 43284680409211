export default {
    getFinancialData(state) {
        return state.financialData
    },
    getBlockchainData(state) {
        return state.blockchainData
    },
    getLoadingStatus(state) {
        return state.loading
    },
    getError(state) {
        return state.error
    },
    getConversionPrices(state) {
        return state.conversionPrices
    },
}
