import {decodeTxnSorobanXdr} from '../../../utils/stellar-sdk';
import sorobanErrors from '@/utils/stellar-errors.json';

import * as StellarSdk from 'stellar-sdk';
const { xdr, StrKey, humanizeEvents } = StellarSdk;
// tslint:disable-line


export default {
    // Fetching transactions and switching pages
    fetchTransactions({commit, rootGetters, dispatch}, limit) {
        commit('resetPageNav')
        commit('setLoading', true)

        return rootGetters['settings/getApiServer']
            .transactions()
            .includeFailed(true)
            .limit(limit)
            .order("desc")
            .call()
            .then(async (response) => {
                const accounts = await dispatch("accounts/formatAccountsFromTransactions", response.records, {root: true})
                await dispatch("accounts/fetchAccountsLabels", {accounts}, {root: true})
                await dispatch('decodeTxnsOperations', response)
                const values = await dispatch('formatTxnsValues', response.records)
                await dispatch("analytics/fetchTxnValue", {values}, {root: true});
                commit('setTransactions', response)
                commit('incrementTxnCount', response.records.length)
            })
            .catch((error) => {
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchNextPage({state, commit, rootGetters, dispatch}) {
        commit('setLoading', true)
        rootGetters['settings/getApiServer'].transactions()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .includeFailed(true)
            .cursor(state.transactions.records[state.transactions.records.length - 1].paging_token)
            .call()
            .then(async (response) => {
                if (response.records.length) {
                    const accounts = await dispatch("accounts/formatAccountsFromTransactions", response.records, {root: true})
                    await dispatch("accounts/fetchAccountsLabels", {accounts}, {root: true})
                    dispatch('decodeTxnsOperations', response)
                    const values = await dispatch('formatTxnsValues', response.records)
                    await dispatch("analytics/fetchTxnValue", {values}, {root: true});
                    commit('setTransactions', response)
                    commit('incrementTxnCount', response.records.length)
                } else {
                    commit('setPageEnd', true)
                }
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });

    },
    fetchPrevPage({commit, state, rootGetters, dispatch}) {
        commit('setLoading', true)
        commit('decrementTxnCount', state.transactions.records.length)
        rootGetters['settings/getApiServer'].transactions()
            .includeFailed(true)
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("asc")
            .cursor(state.transactions.records[0].paging_token)
            .call()
            .then(async (response) => {
                const accounts = await dispatch("accounts/formatAccountsFromTransactions", response.records, {root: true})
                await dispatch("accounts/fetchAccountsLabels", {accounts}, {root: true})
                response.records = response.records.reverse()
                dispatch('decodeTxnsOperations', response)
                const values = await dispatch('formatTxnsValues', response.records)
                await dispatch("analytics/fetchTxnValue", {values}, {root: true});
                commit('setTransactions', response)
                commit('setPageEnd', false)
            })
            .catch((error) => {
                console.log(error)
                commit('incrementTxnCount', state.transactions.records.length)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false);
            });
    },
    async checkTransactionContract(_, response) {
        try {
            let meta = xdr.TransactionMeta.fromXDR(response.result_meta_xdr, 'base64')
            if (meta.v3() && meta.v3().sorobanMeta()){
                response.sorobanEvents = humanizeEvents(meta.v3().sorobanMeta().events());
            }
            let contract = xdr.TransactionEnvelope.fromXDR(response.envelope_xdr, 'base64')
            let footPrint = contract 
                ?.v1()
                ?.tx()
                ?.ext()
                ?.sorobanData()
                ?.resources()
                ?.footprint()

            let readOnly = footPrint?.readOnly()
            let readWrite = footPrint?.readWrite()
            let contractId = null
            let contractType = null
            let contractScVal = null

            if (readOnly) {
                readOnly = readOnly.map((x) => {
                    if (x.value().contract) {
                        contractId = x.value().contract().value().toString('hex');
                        contractScVal = x.value().contract().value();
                        contractType = x.value().contract().switch().value;
                    }
                })
            }

            if (readWrite) {
                readWrite = readWrite.map((x) => {
                    if (x.value().contract && !contractId) {
                        contractScVal = x.value().contract().value();
                        contractId = x.value().contract().value().toString('hex');
                        contractType = x.value().contract().switch().value;
                    }
                })
            }

            if (contractId && contractType == 1) {
                response.contract_id = contractId
                response.contract_type = contractType
            }
        } catch (error) {
            console.log(error)
        }
    },
    getContractsForTxns(_, {dispatch, response}) {
        response.records.forEach(async (txn) => {
            await dispatch("checkTransactionContract", txn);
        });
    },
    // Extract ops from txn XDR
    decodeTxnsOperations(_, txns) {
        return txns.records.map(async (txn) => {
            try {
                txn.operations = decodeTxnSorobanXdr(txn.envelope_xdr).v1().tx().operations()
            }
            catch (error) {
                console.log(error)
                txn.operations = []
            }
        });
    },
    formatSelectedTxnValues(_, operations) {
        let values = []
        operations.forEach(operation => {
            switch (operation.type) {
                case 'create_account':
                    values.push({
                        amount: operation.starting_balance,
                        asset_type: 'native',
                        asset_code: null,
                        asset_issuer: null
                    })
                    break;
                case 'payment':
                case 'path_payment_strict_receive':
                case 'clawback':
                    values.push({
                        amount: operation.amount,
                        asset_type: operation.asset_type,
                        asset_code: operation.asset_code,
                        asset_issuer: operation.asset_issuer
                    })
                    break;
                case 'path_payment_strict_send':
                    values.push({
                        amount: operation.source_amount,
                        asset_type: operation.source_asset_type,
                        asset_code: operation.source_asset_code,
                        asset_issuer: operation.source_asset_issuer
                    })
                    break;
                case 'manage_sell_offer':
                case 'create_passive_sell_offer':
                    values.push({
                        amount: operation.amount,
                        asset_type: operation.selling_asset_type,
                        asset_code: operation.selling_asset_code,
                        asset_issuer: operation.selling_asset_issuer
                    })
                    break;
                case 'manage_buy_offer':
                    values.push({
                        amount: operation.amount,
                        asset_type: operation.buying_asset_type,
                        asset_code: operation.buying_asset_code,
                        asset_issuer: operation.buying_asset_issuer
                    })
                    break;
                case 'create_claimable_balance':
                    {
                        let asset = {}
                        if (operation.asset === 'native') {
                            asset.type = 'native'
                        } else {
                            const arr = operation.asset.split(':')
                            arr[0].length > 4 ? asset.type = 'credit_alphanum12' : asset.type = 'credit_alphanum4'
                            asset.code = arr[0]
                            asset.issuer = arr[1]
                        }
                        values.push({
                            amount: operation.amount,
                            asset_type: asset.type,
                            asset_code: asset.code,
                            asset_issuer: asset.issuer
                        })
                        break;
                    }
                default:
                    break;
            }

        });
        return values
    },
    // Fetching txn by txn hash
    fetchTxnByHash({commit, rootGetters, dispatch}, txnHash) {
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .transactions()
            .limit(process.env.VUE_APP_API_LIMIT)
            .transaction(txnHash)
            .call()
            .then(async (response) => {
                commit('operations/setLoading', true, {root: true})
                try {
                    let meta = xdr.TransactionMeta.fromXDR(response.result_meta_xdr, 'base64').value()
                    if(meta && meta.sorobanMeta){
                        let events = meta?.sorobanMeta()?.events();
                        if (events) {
                            response.sorobanEvents = humanizeEvents(events);
                        }
                    }
                    response.stateChanges = {after: meta.txChangesAfter(), before: meta.txChangesBefore()}
                    if(meta.operations() && meta.operations().length > 0){
                        meta.operations().forEach((operation) => {
                            console.log(operation.changes())
                        });
                    }
                    let result_xdr = xdr.TransactionResult.fromXDR(response.result_xdr, 'base64')
                    if (result_xdr.result().switch().value == -1){
                       response.failed_messages = [];
                       result_xdr.result().value().forEach((value) => {
                           let messages = sorobanErrors[value.value().arm()];
                           let code = value.value().value().switch().value;
                           let opName = value.value().value().switch().name;
                           let description = messages.find(x => x.code === code)?.description
                           response.failed_messages.push({opName, description});
                       }) 
                    }
                    const operations = await response.operations({limit: 25})
                    response.operations = operations.records
                    response.next_operations = operations.records.length ? operations.next : null
                    const values = await dispatch("formatSelectedTxnValues", response.operations)
                    const txnAccs = await dispatch("accounts/formatAccountsFromTransactions", [response], {root: true})
                    const opAccs = await dispatch("accounts/formatAccountsFromOperations", response.operations, {root: true})
                    await dispatch("accounts/fetchAccountsLabels", {accounts: txnAccs.concat(opAccs)}, {root: true})
                    await dispatch("analytics/fetchTxnValue", {values: {[txnHash]: {operations: values, fee: response.fee_charged}}}, {root: true});
                    response.ledger = await response.ledger()
                    dispatch('operations/fetchOperationsAssetsMeta', response.operations, {root: true})
                } catch (error) {
                    commit('operations/setError', error, {root: true})
                    console.log(error)
                } finally {
                    commit('operations/setLoading', false, {root: true})
                }
                await dispatch('checkTransactionContract', response)
                commit('setSelectedTxn', response)
            })
            .catch((error) => {
                commit('setError', error)
                console.log(error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    async fetchMoreOpsForTxn({getters, commit, dispatch}) {
        if (getters.getSelectedTxn.next_operations) {
            commit('operations/setLoading', true, {root: true})
            commit('operations/setError', null, {root: true})
            try {
                const operations = await getters.getSelectedTxn.next_operations()
                commit('loadMoreOpsForTxn', {operations: operations.records, next_operations: operations.records.length ? operations.next : null})
                const values = await dispatch("formatSelectedTxnValues", getters.getSelectedTxn.operations)
                const txnAccs = await dispatch("accounts/formatAccountsFromTransactions", [getters.getSelectedTxn], {root: true})
                const opAccs = await dispatch("accounts/formatAccountsFromOperations", getters.getSelectedTxn.operations, {root: true})
                await dispatch("accounts/fetchAccountsLabels", {accounts: txnAccs.concat(opAccs)}, {root: true})
                await dispatch("analytics/fetchTxnValue", {values: {[getters.getSelectedTxn.hash]: {operations: values, fee: getters.getSelectedTxn.fee_charged}}}, {root: true});
            } catch (error) {
                commit('operations/setError', error, {root: true})
            } finally {
                commit('operations/setLoading', false, {root: true})
            }
        }
    },
    async startStreaming({rootGetters, commit, dispatch}) {
        await dispatch("fetchTransactions", process.env.VUE_APP_API_LIMIT);
        commit('setStreaming', true)
        const stop = setInterval(() => {
            rootGetters['settings/getApiServer']
                .transactions()
                .includeFailed(true)
                .limit(process.env.VUE_APP_API_LIMIT)
                .order("desc")
                .call()
                .then(async (response) => {
                    const accounts = await dispatch("accounts/formatAccountsFromTransactions", response.records, {root: true})
                    await dispatch("accounts/fetchAccountsLabels", {accounts}, {root: true})
                    await dispatch('decodeTxnsOperations', response)
                    const values = await dispatch('formatTxnsValues', response.records)
                    await dispatch("analytics/fetchTxnValue", {values}, {root: true});

                    commit('setTransactions', response)
                })
                .catch((error) => {
                    console.log(error)
                    commit('setError', error)
                    commit('setStreaming', false)
                    commit('setStreamIntervalId', null)
                })
        }, 5000)
        commit('setStreamIntervalId', stop)
    },
    stopStreaming({commit, getters}) {
        clearInterval(getters.getStreamIntervalId)
        commit('setStreaming', false)
        commit('setStreamIntervalId', null)
    },
    formatTxnsValues(_, transactions) {
        let values = {}
        transactions.forEach(transaction => {
            values[transaction.hash] = {}
            values[transaction.hash]['operations'] = []
            if ('operations' in transaction) {
                transaction.operations.forEach(operation => {
                    switch (operation.body().switch().name) {
                        case 'createAccount': {
                            values[transaction.hash]['operations'].push({
                                amount: operation.body().value().startingBalance().toString() * 0.0000001,
                                asset_type: 'native',
                                asset_code: null,
                                asset_issuer: null
                            })
                            break;
                        }
                        case 'pathPaymentStrictReceive':
                            {
                                let assetCode = null;
                                let assetIssuer = null;
                                if (operation.body().value().destAsset().switch().name !== 'assetTypeNative') {
                                    assetCode = Array.from(operation.body().value().destAsset().value().assetCode());
                                    while (!assetCode[assetCode.length - 1]) {
                                        assetCode.pop()
                                    }
                                    assetCode = new TextDecoder('utf-8').decode(new Uint8Array(assetCode))
                                    assetIssuer = StrKey.encodeEd25519PublicKey(operation.body().value().destAsset().value().issuer().ed25519())
                                }

                                values[transaction.hash]['operations'].push({
                                    amount: operation.body().value().destAmount().toString() * 0.0000001,
                                    asset_type: operation.body().value().destAsset().switch().name,
                                    asset_code: assetCode,
                                    asset_issuer: assetIssuer
                                })
                                break;
                            }
                        case 'manageSellOffer':
                        case 'createPassiveSellOffer':
                            {
                                let assetCode = null;
                                let assetIssuer = null;
                                if (operation.body().value().selling().switch().name !== 'assetTypeNative') {
                                    assetCode = Array.from(operation.body().manageSellOfferOp().selling().value().assetCode());
                                    while (!assetCode[assetCode.length - 1]) {
                                        assetCode.pop()
                                    }
                                    assetCode = new TextDecoder('utf-8').decode(new Uint8Array(assetCode))
                                    assetIssuer = StrKey.encodeEd25519PublicKey(operation.body().manageSellOfferOp().selling().value().issuer().ed25519())
                                }

                                values[transaction.hash]['operations'].push({
                                    amount: operation.body().value().amount().toString() * 0.0000001,
                                    asset_type: operation.body().value().selling().switch().name,
                                    asset_code: assetCode,
                                    asset_issuer: assetIssuer
                                })
                                break;
                            }

                        case 'manageBuyOffer':
                            {
                                let assetCode = null;
                                let assetIssuer = null;

                                if (operation.body().value().buying().switch().name !== 'assetTypeNative') {
                                    assetCode = Array.from(operation.body().manageBuyOfferOp().buying().value().assetCode());
                                    while (!assetCode[assetCode.length - 1]) {
                                        assetCode.pop()
                                    }
                                    assetCode = new TextDecoder('utf-8').decode(new Uint8Array(assetCode))
                                    assetIssuer = StrKey.encodeEd25519PublicKey(operation.body().manageBuyOfferOp().buying().value().issuer().ed25519())
                                }

                                values[transaction.hash]['operations'].push({
                                    amount: operation.body().value().buyAmount().toString() * 0.0000001,
                                    asset_type: operation.body().value().buying().switch().name,
                                    asset_code: assetCode,
                                    asset_issuer: assetIssuer
                                })
                                break;
                            }
                        case 'pathPaymentStrictSend': {
                            let assetCode = null;
                            let assetIssuer = null;

                            if (operation.body().value().sendAsset().switch().name !== 'assetTypeNative') {
                                assetCode = Array.from(operation.body().value().sendAsset().value().assetCode());
                                while (!assetCode[assetCode.length - 1]) {
                                    assetCode.pop()
                                }
                                assetCode = new TextDecoder('utf-8').decode(new Uint8Array(assetCode))
                                assetIssuer = StrKey.encodeEd25519PublicKey(operation.body().value().sendAsset().value().issuer().ed25519())
                            }

                            values[transaction.hash]['operations'].push({
                                amount: operation.body().value().sendAmount().toString() * 0.0000001,
                                asset_type: operation.body().value().sendAsset().switch().name,
                                asset_code: assetCode,
                                asset_issuer: assetIssuer
                            })
                            break;
                        }
                        case 'payment':
                        case 'createClaimableBalance':
                        case 'clawback': {
                            let assetCode = null;
                            let assetIssuer = null;

                            if (operation.body().value().asset().switch().name !== 'assetTypeNative') {
                                assetCode = Array.from(operation.body().value().asset().value().assetCode());
                                while (!assetCode[assetCode.length - 1]) {
                                    assetCode.pop()
                                }
                                assetCode = new TextDecoder('utf-8').decode(new Uint8Array(assetCode))
                                assetIssuer = StrKey.encodeEd25519PublicKey(operation.body().value().asset().value().issuer().ed25519())
                            }

                            values[transaction.hash]['operations'].push({
                                amount: operation.body().value().amount().toString() * 0.0000001,
                                asset_type: operation.body().value().asset().switch().name,
                                asset_code: assetCode,
                                asset_issuer: assetIssuer
                            })
                            break;
                        }
                        default:
                            break;
                    }
                });
            }
            values[transaction.hash]['fee'] = transaction.fee_charged * 0.0000001
        });
        return values;
    }
}
