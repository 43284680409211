import { decodeTxnSorobanXdr as decodeTxnXdr} from '@/utils/stellar-sdk.js'

export default {
    fetchLedgers({ commit, rootGetters }, limit) {
        commit('resetLedgersCount')
        commit('setLoading', true)
        return rootGetters['settings/getApiServer'].ledgers()
            .limit(limit)
            .order("desc")
            .cursor('now')
            .call()
            .then((response) => {
                commit('setLedgers', response)
                commit('incrementLedgersCount', response.records.length)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchNextPage({ state, commit, rootGetters }) {
        commit('setLoading', true)
        rootGetters['settings/getApiServer'].ledgers()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .cursor(state.ledgers.records[state.ledgers.records.length - 1].paging_token)
            .call()
            .then((response) => {
                commit('setLedgers', response)
                commit('incrementLedgersCount', response.records.length)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchPrevPage({ commit, state, rootGetters }) {
        commit('setLoading', true)
        commit('decrementLedgersCount', state.ledgers.records.length)
        rootGetters['settings/getApiServer'].ledgers()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("asc")
            .cursor(state.ledgers.records[0].paging_token)
            .call()
            .then((response) => {
                response.records = response.records.reverse()
                commit('setLedgers', response)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
                commit('incrementLedgersCount', state.ledgers.records.length)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchLedgerBySeq({ commit, rootGetters, dispatch }, ledgerSequence) {
        commit('setLoading', true)
        commit('setError', null)
        rootGetters['settings/getApiServer']
            .ledgers()
            .ledger(ledgerSequence)
            .call()
            .then(async (response) => {
                commit('transactions/setLoading', true, { root: true })
                try {
                    const transactions = await response.transactions({ order: 'desc', limit: 25, include_failed:true})
                    const accounts = await dispatch("accounts/formatAccountsFromTransactions", transactions.records, { root: true })
                    await dispatch("accounts/fetchAccountsLabels", { accounts }, { root: true })
                    await dispatch('decodeTxnsOperations', transactions)
                    const values = await dispatch('transactions/formatTxnsValues', transactions.records, { root: true })
                    await dispatch("analytics/fetchTxnValue", { values, append: true }, { root: true });
                    await dispatch("analytics/fetchLedgerValue", values, { root: true });
                    response.transactions = transactions.records
                    response.next_transactions = transactions.records.length ? transactions.next : null
                } catch (error) {
                    commit('transactions/setError', error, { root: true })
                } finally {
                    commit('transactions/setLoading', false, { root: true })
                }
                commit('setSelectedLedger', response)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    async fetchMoreTxnsForLedger({ getters, commit, dispatch }) {
        if (getters.getSelectedLedger.next_transactions) {
            try {
                commit('transactions/setLoading', true, { root: true })
                const transactions = await getters.getSelectedLedger.next_transactions({limit:25, include_failed: true})
                await dispatch('decodeTxnsOperations', transactions)
                commit('loadMoreTxnsForLedger', { transactions: transactions.records, next_transactions: transactions.records.length ? transactions.next : null })
                const values = await dispatch('transactions/formatTxnsValues', getters.getSelectedLedger.transactions, { root: true })
                await dispatch("analytics/fetchTxnValue", { values, append: true }, { root: true });
                await dispatch("analytics/fetchLedgerValue", values, { root: true });
            } catch (error) {
                commit('transactions/setError', error, { root: true })
            } finally {
                commit('transactions/setLoading', false, { root: true })
            }
        }
    },
    // Extract ops from txn XDR
    decodeTxnsOperations(_, txns) {
        return txns.records.map(async (txn) => {
            try {
                txn.operations = decodeTxnXdr(txn.envelope_xdr)?.v1().tx().operations()
                console.log(txn.operations)
            }
            catch (error) {
                console.log(error)
                txn.operations = []
            }
        });
    },
    async startStreaming({ getters, rootGetters, commit, dispatch }, limit) {
        const ledgerHandler = (response) => {
            commit('addStreamedLedger', response)
        }

        const errorHandler = (error) => {
            commit('setError', error)
            commit('setStreaming', false)
            commit('setStopStreamFunc', null)
        }
        await dispatch("fetchLedgers", limit);

        if (!getters.isStreaming) {
            {
                commit('setStreaming', true)
                const stop = rootGetters['settings/getApiServer']
                    .ledgers()
                    .cursor('now')
                    .stream({
                        onmessage: ledgerHandler,
                        onerror: errorHandler
                    })
                commit('setStopStreamFunc', stop)
            }
        }
    },
    stopStreaming({ getters, commit }) {
        if (getters.isStreaming) {
            getters.getStopStreamFunc()
            commit('setStreaming', false)
            commit('setStopStreamFunc', null)
        }
    },
    fetchLedgerEffects({ getters, commit }) {
        commit('setLoadingTab', true)
        getters.getSelectedLedger.effects({limit: 25}).then((effects) => {
            commit('setLedgerEffects', effects)
        }).catch((error) => {
            console.log(error)
            commit('setError', error)
        })
        .finally(() => {
            commit('setLoadingTab', false)
        });
    },
    fetchMoreLedgerEffects({ getters, commit }) {
        if(getters.getLedgerEffectsNext){
            commit('setLoadingTab', true)
            getters.getLedgerEffectsNext().then((response) => {
                    if (!response.records.length) {
                        response.next = null
                    }
                    commit('setMoreLedgerEffects', response) 
                })
                .catch((error) => {
                    console.log(error)
                    commit('setError', error)
                })
                .finally(() => {
                    commit('setLoadingTab', false)
                });
        }
    },
    fetchLedgerPayments({ getters, commit }) {
        commit('setLoadingTab', true)
        getters.getSelectedLedger.payments({limit:25}).then((payments) => {
            commit('setLedgerPayments', payments)
        }).catch((error) => {
            console.log(error)
            commit('setError', error)
        })
        .finally(() => {
            commit('setLoadingTab', false)
        });
    },
    fetchMoreLedgerPayments({ getters, commit}) {
        if(getters.getLedgerPaymentsNext){
            commit('setLoadingTab', true)
            getters.getLedgerPaymentsNext().then((response) => {
                    if (!response.records.length) {
                        response.next = null
                    }
                    commit('setMoreLedgerPayments', response) 
                })
                .catch((error) => {
                    console.log(error)
                    commit('setError', error)
                })
                .finally(() => {
                    commit('setLoadingTab', false)
                });
        }
    },
    fetchLedgerOperations({ getters, commit}) {
        commit('setLoadingTab', true)
        getters.getSelectedLedger.operations({limit: 25}).then((operations) => {
            commit('setLedgerOperations', operations)
        }).catch((error) => {
            console.log(error)
            commit('setError', error)
        })
        .finally(() => {
            commit('setLoadingTab', false)
        });
    },
    fetchMoreLedgerOperations({ getters, commit }) {
        if(getters.getLedgerOperationsNext){
            commit('setLoadingTab', true)
            getters.getLedgerOperationsNext().then((response) => {
                    if (!response.records.length) {
                        response.next = null
                    }
                    commit('setMoreLedgerOperations', response) 
                })
                .catch((error) => {
                    console.log(error)
                    commit('setError', error)
                })
                .finally(() => {
                    commit('setLoadingTab', false)
                });
        }
    },
}
