export default {
    getLatestLedgers(state) {
        return "records" in state.ledgers ? state.ledgers.records : []
    },
    getLoadingStatus(state) {
        return state.loading
    },
    getLoadingTabStatus(state) {
        return state.loadingTab
    },
    getError(state) {
        return state.error
    },
    getSelectedLedger(state) {
        return state.selectedLedger
    },
    disablePrevBtn(state, getters) {
        return state.error || state.loading || state.streaming || !getters.getLatestLedgers.length || state.ledgersCount <= process.env.VUE_APP_API_LIMIT
    },
    disableNextBtn(state, getters) {
        return state.error || state.loading || state.streaming || !getters.getLatestLedgers.length
    },
    getStopStreamFunc(state) {
        return state.stopStreamFunc
    },
    isStreaming(state) {
        return state.streaming
    },
    getLedgerEffects(state) {
        return "records" in state.ledgerEffects ? state.ledgerEffects.records : []
    },
    getLedgerEffectsNext(state) {
        return state.ledgerEffects.next
    },
    getLedgerOperations(state) {
        return "records" in state.ledgerOperations ? state.ledgerOperations.records : []
    },
    getLedgerOperationsNext(state) {
        return state.ledgerOperations.next
    },
    getLedgerPayments(state) {
        return "records" in state.ledgerPayments ? state.ledgerPayments.records : []
    },
    getLedgerPaymentsNext(state) {
        return state.ledgerPayments.next
    }
}
