import en from "./en.json";
import { createI18n } from "vue-i18n";

export default createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  fallbackLocale: "en",
  messages: {
    en,
  },
});
