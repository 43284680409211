export default {
    // Fetching offers and switching pages
    fetchOffers({ commit, rootGetters, dispatch }) {
        commit('resetPageNav')
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .offers()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .call()
            .then(async (response) => {
                commit('setOffers', response)
                commit('incrementOffersCount', response.records.length)
                const accounts = await dispatch("accounts/formatAccountsFromOffers", response.records, { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts }, { root: true })
                dispatch('fetchOffersAssetsMeta', response.records)
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchNextPage({ state, commit, rootGetters, dispatch }) {
        commit('setLoading', true)
        rootGetters['settings/getApiServer']
            .offers()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("desc")
            .cursor(state.offers.records[state.offers.records.length - 1].paging_token)
            .call()
            .then(async (response) => {
                if (response.records.length) {
                    commit('setOffers', response)
                    commit('incrementOffersCount', response.records.length)
                    const accounts = await dispatch("accounts/formatAccountsFromOffers", response.records, { root: true })
                    await dispatch("accounts/fetchAccountsLabels", { accounts }, { root: true })
                    dispatch('fetchOffersAssetsMeta', response.records)
                } else {
                    commit('setPageEnd', true)
                }
            })
            .catch((error) => {
                console.log(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });

    },
    fetchPrevPage({ commit, state, rootGetters, dispatch }) {
        commit('setLoading', true)
        commit('decrementOffersCount', state.offers.records.length)
        rootGetters['settings/getApiServer']
            .offers()
            .limit(process.env.VUE_APP_API_LIMIT)
            .order("asc")
            .cursor(state.offers.records[0].paging_token)
            .call()
            .then(async (response) => {
                response.records = response.records.reverse()
                commit('setOffers', response)
                commit('setPageEnd', false)
                const accounts = await dispatch("accounts/formatAccountsFromOffers", response.records, { root: true })
                await dispatch("accounts/fetchAccountsLabels", { accounts }, { root: true })
                dispatch('fetchOffersAssetsMeta', response.records)
            })
            .catch((error) => {
                console.log(error)
                commit('incrementOffersCount', state.offers.records.length)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    // Fetch assets meta for offers that have assets
    fetchOffersAssetsMeta({ dispatch }, offers) {
        let assets = []
        offers.forEach((offer) => {
            assets.push({ asset_type: offer.selling.asset_type, asset_code: offer.selling.asset_code, asset_issuer: offer.selling.asset_issuer })
            assets.push({ asset_type: offer.buying.asset_type, asset_code: offer.buying.asset_code, asset_issuer: offer.buying.asset_issuer })

        })
        assets = assets.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.asset_type === value.asset_type &&
                t.asset_code === value.asset_code &&
                t.asset_issuer === value.asset_issuer
            ))
        )
        assets = [...assets]
        if (assets.length)
            dispatch('assets/fetchAssetsMeta', assets, { root: true })
    },
    // Start/stop streaming offers
    async startStreaming({ rootGetters, commit, dispatch }) {
        await dispatch("fetchOffers", process.env.VUE_APP_API_LIMIT);
        commit('setStreaming', true)
        const stop = setInterval(() => {
            rootGetters['settings/getApiServer']
                .offers()
                .limit(process.env.VUE_APP_API_LIMIT)
                .order("desc")
                .call()
                .then(async (response) => {
                    console.log(response)
                    commit('setOffers', response);
                    const accounts = await dispatch("accounts/formatAccountsFromOffers", response.records, { root: true })
                    await dispatch("accounts/fetchAccountsLabels", { accounts }, { root: true })
                    dispatch('fetchOffersAssetsMeta', response.records)
                })
                .catch((error) => {
                    console.log(error)
                    commit('setError', error)
                    commit('setStreaming', false)
                    commit('setStreamIntervalId', null)
                })
        }, 5000)
        commit('setStreamIntervalId', stop)
    },
    stopStreaming({ commit, getters }) {
        clearInterval(getters.getStreamIntervalId)
        commit('setStreaming', false)
        commit('setStreamIntervalId', null)
    },
}
